<template>
  <button
    class="btn-container"
    :class="{ 'btn-orange': isOrange, 'btn-outline': isOutLine }"
  >
    <img v-if="pathSvg != null" :src="pathSvg" @click="active" alt="" />
    <p @click="active">{{ txt }}</p> {{txtButton}}&#160;
    <img v-if="pathSvgRight != null" :src="pathSvgRight" alt="" />
    <slot name="icon"></slot>
  </button>
</template>

<script>
export default {
  props: {
    txt: {
      type: String,
      required: true
    },
      txtButton: {
          type: String,
          required: false
      },
    pathSvg: {
      type: String
    },
    isOrange: {
      type: Boolean,
      default: true
    },
    isOutLine: {
      type: Boolean,
      default: false
    },

    pathSvgRight: {
      type: String
    },
    active: {
      type: Function,
      required: false
    }
  }
};
</script>

<style scoped>
.btn-container {
  all: unset;
  height: 2.5em;
  width: 13.5em;
  margin: 0 auto;
  padding: 0 4em;
  cursor: pointer;
  font-size: 14px;
  line-height: 0.1;
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  background-color: #fff;
  color: #ff9012;
  border: 1px solid #ff9012;
}

.btn-orange {
  background-color: #ff9012;
  color: #fff;
}
.btn-outline {
  color: #ff9012;
  border: 1px solid #ff9012;
}

@media (min-width: 600px) {
  .btn-container {
    padding: 0 2em;
    font-size: 18px;
  }
}
/*** this class is passed as prop */
.btn-disable {
  background-color: #dfdfdf;
}
</style>

<template>
  <div class="order-header">
    <div class="circle"></div>
    <h4 class="order-header__text">Pedido # {{ order.id }}</h4>
  </div>
  <div class="order-detail ">
    <p class="order-detail__info">
      <b class="order-detail__info order-detail__info--tag">Tienda:</b>
      {{ order.retailer }}
    </p>
  </div>
</template>
<script>
export default {
  name: "the-order-details",

  data() {
    return { showDetailOrder: true };
  },
  props: {
    order: {
      required: true
    }
  }
};
</script>
<style scoped>
p {
  color: #606060;
  margin: 0.25rem;
}
.order-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
}
.order-header__text {
  margin: 0 2rem;
  font-weight: 400;
  font-size: 23px;
  text-transform: uppercase;
  color: #ff9012;
  white-space: nowrap;
}
.circle {
  height: 15px;
  width: 15px;
  margin: 0 3px 0 0;
  border-radius: 50%;
  background-color: #ff9012;
}
.order-detail {
  width: 100%;
}
.order-detail__info {
  margin: 0.25rem 0;
  text-align: justify;
  font-weight: 400;
  font-size: 14px;
}
.order-detail__info--tag {
  font-weight: 600;
}
</style>

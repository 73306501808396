import { logger } from "@/utils/logger";

export default {
  async registerBuilding({ rootGetters }, payload) {
    try {
      const url = "v2/residential_complex_api_view";
      const data = {
        name: payload["name"],
        main_street: payload["main_street"],
        secondary_street: payload["secondary_street"],
        entrance_phone: payload["entrance_phone"],
        administration_phone: payload["administration_phone"],
        house_number: payload["house_number"],
        number_apartments: payload["number_apartments"],
        security_company_name: payload["security_company_name"],
        administration_software: payload["administration_software"],
        enable: true
      };
      if (payload["document"]) {
        data.document = payload["document"];
      }
      await rootGetters["network/axios"].post(url, data);
      this.showSuccessAlert = true;
    } catch (err) {
      throw err.response.status;
    }
  },
  async updateBuilding({ rootGetters }, payload) {
    try {
      const url = `v2/residential_complex_api_view/${payload["id"]}`;
      const dataEdit = {
        name: payload["name"],
        main_street: payload["main_street"],
        secondary_street: payload["secondary_street"],
        entrance_phone: payload["entrance_phone"],
        administration_phone: payload["administration_phone"],
        house_number: payload["house_number"],
        number_apartments: payload["number_apartments"],
        security_company_name: payload["security_company_name"],
        administration_software: payload["administration_software"],
        enable: true
      };
      if (payload["document"]) {
        dataEdit.document = payload["document"];
      }
      await rootGetters["network/axios"].put(url, dataEdit);
      this.showSuccessAlert = true;
    } catch (err) {
      throw err.response.status;
    }
  },

  async getBuilding({ rootGetters, commit }) {
    try {
      const url = "v2/residential_complex_api_view";
      const { data } = await rootGetters["network/axios"].get(url);
      commit("building", data);
      commit("indexBuildingState", 0);
    } catch (err) {
      logger("INFORMATION", err);
    }
  },

  async registerAccessPoint({ rootGetters, commit }, payload) {
    try {
      const url = `v2/residential_complex/${payload["building"]}/access_points/crud/`;
      const data = {
        main_street: payload["main_street"],
        secondary_street: payload["secondary_street"],
        reference: payload["reference"]
      };
      const resp = await rootGetters["network/axios"].post(url, data);
      commit("addAccessPoint", resp["data"]);
    } catch (err) {
      throw err.response.status;
    }
  },

  async getBuildingAccessPoint({ rootGetters, commit }, payload) {
    try {
      const url = `v2/residential_complex/${payload["id"]}/access_points`;
      const { data } = await rootGetters["network/axios"].get(url);
      commit("accessPoint", data);
      return data;
    } catch (err) {
      logger("INFORMATION", err);
    }
  },
  async updateBuildingAccessPoint({ rootGetters, commit }, payload) {
    try {
      const url = `v2/residential_complex/${payload["id"]}/access_points/crud/`;
      const data = {
        main_street: payload["main_street"],
        secondary_street: payload["secondary_street"],
        reference: payload["reference"]
      };
      await rootGetters["network/axios"].put(url, data);
      commit("editAccessPoint", { ...payload });
    } catch (err) {
      throw err.response.status;
    }
  },
  async deleteBuildingAccessPoint({ rootGetters, commit }, payload) {
    try {
      const url = `v2/residential_complex/${payload["id"]}/access_points/crud/`;
      await rootGetters["network/axios"].delete(url);
      commit("deleteAccessPoint", payload["id"]);
    } catch (err) {
      throw err.response.status;
    }
  }
};

<template>
  <form class="form-login" @submit.prevent="login">
    <h2 class="title">Bienvenido</h2>
    <p class="message">Ingresa tus credenciales para acceder a tu cuenta.</p>
    <div class="inputs-container">
      <base-input
        label="Usuario:"
        placeholder="Ingresa tu Email"
        autocomplete="username"
        v-model.trim="username"
        type="email"
      />
      <base-input
        label="Contraseña:"
        placeholder="Ingresa tu contraseña"
        type="password"
        autocomplete="current-password"
        v-model.trim="password"
      />
      <p class="text-info">Si olvidaste tu  <b>usuario</b> o <b>contraseña</b>. Por favor comunicate con  Care Team</p>
      <p class="error" v-if="hasError">
        Error de inicio, verifica que tu usuario y contraseña sean correctos
      </p>
    </div>
    <loading v-if="isLoading"></loading>
    <text-button-solid
      v-else
      :is-disable="isDisable"
      text="Ingresar"
      @click="login"
    />
    <p>
      O
    </p>
    <span class="register-link" @click="$emit('switch-form')"
      >Registro de Administrador <br> de Conjuntos</span
    >
  </form>
</template>

<script>
import TextButtonSolid from "./UI/buttons/TextButtonSolid.vue";
import BaseInput from "@/components/UI/inputs/BaseInput.vue";
import Loading from "@/components/UI/loading/Loading.vue";
import { mapGetters } from "vuex";

export default {
  name: "the-login",
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
      hasError: false
    };
  },
  emits: ["switchForm"],
  components: {
    TextButtonSolid,
    BaseInput,
    Loading
  },
  watch: {
    username(_, __) {
      this.hasError = false;
    },
    password(_, __) {
      this.hasError = false;
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "user/isAdmin"
    }),
    isDisable() {
      return !this.username.length || !this.password.length;
    }
  },
  methods: {
    async login() {
      try {
        if (this.isLoading || this.isDisable) return;
        this.isLoading = true;
        await this.$store.dispatch("user/login", {
          username: this.username,
          password: this.password
        });
          this.isAdmin
          ? await this.$router.push("/home-admin")
          : await this.$router.push("/home");
      } catch (err) {
        this.hasError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.form-login {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 1rem 0;
}
.inputs-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}
.title {
  font-size: 62px;
  margin: 0;
  color: #ff9012;
  font-weight: 600;
}
.message {
  font-size: 14px;
  color: #606060;
  align-self: flex-start;
}
p {
  color: #606060;
  font-size: 12px;
  align-self: center;
}
.error {
  margin: 0;
  color: #c90808;
  font-size: 12px;
  text-align: justify;
}
.text-info {
  color: #C7C7C7;
  font-size: 12px;
  text-align: center;
}
.register-link {
  color: #ff9012;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
    text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
@media (min-width: 600px) {
  h2 {
    font-size: 30px;
  }
  p {
    font-size: 16px;
  }
}
</style>

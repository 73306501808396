export default {
  building(state) {
    return state.building ?? '';
  },
  accessPoint(state) {
    return state.accessPoint ;
  },
  indexBuildingState(state) {
    return state.indexBuildingState;
  }
};

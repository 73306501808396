<template>
  <section class="header">
    <router-link
      class="header__tipti"
      :to="isAdmin ?   'home-admin' :  '/Home'"
    >
      <icon-tipti-logo color="#ff9012" :size="0.3" />
    </router-link>
    <slot name="right"></slot>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import IconTiptiLogo from "@/components/icons/IconTipti";
export default {
  name: "TheHeader",
  components: {
    IconTiptiLogo
  },
  computed: {
    ...mapGetters({
      isAuth: "user/isAuth",
      isAdmin: "user/isAdmin"
    })
  },
  mounted() {
    this.$store.dispatch("user/updateCareTeamChat");
  }
};
</script>

<style scoped>
.header {
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: #ffffff;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0 1.05px 4.2px rgba(0, 0, 0, 0.1);
}
.header__tipti {
  justify-self: start;
  padding: 0.5rem;
}
</style>

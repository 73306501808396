<template>
  <div class="btn-report">
    <div class="report-case" @click="openReportCaseModal">
      <p>REPORTAR INCONVENIENTES</p>
    </div>
    <teleport to="body">
      <report-case-modal
        :guardId="guardId"
        :shopperId="shopperId"
        :shopperAvailable="shopperAvailable"
        @on-error="onError"
        v-if="showReportCaseModal"
        :onClose="closeReportCaseModal"
      ></report-case-modal>
    </teleport>
  </div>
  <teleport to="body">
    <alert
      @click="showErrorAlert = false"
      backgroundColor="transparent"
      body="Fallo en el envío del reporte, por favor inténtalo más tarde."
      v-if="showErrorAlert"
    ></alert>
  </teleport>
</template>

<script>
import ReportCaseModal from "./ReportCaseModal.vue";
import Alert from "@/components/UI/alerts/Alert.vue";

export default {
  name: "TheReportCase",
  data() {
    return {
      showReportCaseModal: false,
      showErrorAlert: false
    };
  },
  components: {
    ReportCaseModal,
    Alert
  },
  props: {
    shopperId: {
      type: Number,
      default: 0
    },
    guardId: {
      type: Number,
    },
    shopperAvailable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onError() {
      this.showErrorAlert = true;
      this.showReportCaseModal = false;
    },
    openReportCaseModal() {
      this.showReportCaseModal = true;
    },
    closeReportCaseModal() {
      this.showReportCaseModal = false;
    }
  }
};
</script>

<style scoped>
.report-case {
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
}
p {
  color: #ffffff;
  font-size: 15px;
  text-align: center;
}
.btn-report {
  padding: 15px;
  height: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: none;
  background-color: #c90808;
}
</style>

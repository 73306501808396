import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
const state = {
  shopper: null,
  listShopper: [],
    validationCodeShopper: 'Código incorrecto, por favor ingrésalo nuevamente.',
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

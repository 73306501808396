<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <base-card class="alert" :class="{ success: isSuccess }">
        <div class="alert-header">
          <div />
          <h3 class="alert-header__title">{{ title }}</h3>
          <img
            class="alert-header__icon"
            src="@/assets/svg/ic_close.svg"
            alt="cerrar alerta"
          />
        </div>
        <p class="alert__body" :class="{'alert__body--only-body': !title.length}">{{ body }}</p>
      </base-card>
    </div>
  </div>
</template>

<script>
import BaseCard from "@/components/UI/card/BaseCard.vue";
export default {
  name: "alert",
  components: {
    BaseCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    body: {
      type: String,
      required: true,
    },
    backgroundColor : {
      type: String,
      default: '#00000080',
    }
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: v-bind(backgroundColor);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  position: absolute;
  right: 0;
  top: 4rem;
}
.alert {
  padding: 1rem;
  width: fit-content;
  border-radius: 1rem 0 0 1rem;
  border-left: 1rem solid #c90808;
  background-color: #fff;
}
.success {
  border-left-color: #cfde00;
}
.alert-header {
  display: grid;
  align-items: center;
  width: 100%;
  justify-items: center;
  grid-template-columns: 10% 1fr 10%;
}
.alert-header__title {
  font-size: 22px;
  margin: 0;
  color: #ff9012;
}
.alert-header__icon {
  justify-self: flex-end;
  cursor: pointer;
}
.alert__body {
  font-size: 18px;
  max-width: 25rem;
  text-align: center;
  color: #606060;
}
.alert__body--only-body {
  margin-top: 0;
  margin-right: 30px;
}
</style>
<template>
  <div class="date-range-dialog">
    <div class="dialog-overlay" @click="$emit('close-dialog')"></div>
      <form @submit.prevent="postAccessPoint" ref="anyName">
    <div class="dialog-content">

      <div class="header">
        <h2>{{ title }}</h2>
        <img
          class="img-close"
          @click="$emit('close-dialog')"
          src="@/assets/svg/ic_close.svg"
          alt="cerrar modal"
        />
      </div>

      <div class="date-row">
        <div class="date-col">
          <label class="instruction-label"
            >Calle primaria:</label
          >
          <input
            class="input-access-point icon-rtl"
            placeholder="Ingresa la calle primaria"
            type="text"
            v-model="main_street"
            maxlength="98"
          />
        </div>
        <div class="date-col">
          <label class="instruction-label"
            >Calle secundaria:</label
          >
          <input
            class="input-access-point icon-rtl"
            placeholder="Ingresa la calle secundaria"
            type="text"
            maxlength="98"
            v-model="secondary_street"
          />
        </div>
        <div class="date-col">
          <label class="instruction-label" >Referencia:</label>
          <input
            class="input-access-point-reference"
            placeholder="Edificio, piso, departamento"
            type="text"
            maxlength="98"
            v-model="reference"
          />
          <hr class="solid" />
        </div>
      </div>
      <div class="validation-row">
        <span v-if="showValidationError" class="validation-error">
          Por favor, Ingrese punto de acceso y referencia.
        </span>
        <span v-if="showRequestError" class="validation-error">
          Uups, algo salio mal, intentalo nuevamente en un momento.
        </span>
          <span v-if="maxlength">
              Maximo 100 caracteres por campo.
          </span>
      </div>

        <loading v-if="isLoading"></loading>
        <text-button-solid v-else :is-disable="isDisable" text="Agregar punto" />
    </div>
      </form>
  </div>

  <teleport to="body">
    <alert
      @click="showSuccessAlert = false"
      backgroundColor="transparent"
      body="Se ha creado el Punto de acceso"
      v-if="showSuccessAlert"
      isSuccess
    ></alert>
  </teleport>
</template>

<script>

import Alert from "@/components/UI/alerts/Alert.vue";
import Loading from "@/components/UI/loading/Loading.vue";
import TextButtonSolid from "@/components/UI/buttons/TextButtonSolid.vue";


export default {
  name: "AccessPointBuilding",

  components: {
      TextButtonSolid, Loading,
    Alert,

  },

  computed: {
      accessPointBuilding() {
          return this.$store.state.building.accessPoint;
      },
      isDisable() {
          return (
              !this.main_street.length ||
              !this.secondary_street.length ||
              !this.reference.length
          );
      }
  },

  props: {
    title: {
      type: String,
      default: "Confirma el punto de acceso"
    },
    building: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      main_street: "",
      secondary_street: "",
      reference: "",
      showValidationError: false,
      showSuccessAlert: false,
      showRequestError: false,
        isLoading: false,
        maxlength:false
    };
  },

  methods: {
    async postAccessPoint() {
        if (this.main_street.length > 98 || this.secondary_street.length > 98|| this.reference.length > 98) {
            this.maxlength = true;
            return;
        }
        this.maxlength = false;
      this.showRequestError = false;
      if (this.isDisable) {
        this.showValidationError = true;
        return;
      }
      this.showValidationError = false;
      let payload = {
        main_street: this.main_street,
        secondary_street: this.secondary_street,
        reference: this.reference,
        building: this.building
      };
      try {
        await this.$store.dispatch("building/registerAccessPoint", payload);
        this.isLoading = true;
        setTimeout(() => {

          this.$emit("close-dialog");
        }, 2000);
        this.showSuccessAlert = true;
      } catch (error) {
        this.showRequestError = true;
      } finally {
        this.isLoading = false;
      }
    }
  },
  emits: ["close-dialog"]
};
</script>

<style scoped>
.date-range-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.dialog-content {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 2.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

h2 {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 200;
  color: #ff9012;
  flex-grow: 1;
}

.img-close {
  align-self: center;
  height: 30px;
}

.instruction-label {
  text-align: center;
  padding: 0.25rem;
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 200;
  color: #ff9012;
}

.date-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 0 1rem 0;
  width: 100%;
}

.date-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}
hr.solid {
  width: 100%;
  border-top: 1px solid #bbb;
}
.date-label {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #404040;
}

.btn-access-point {
  color: #ffffff;
  border: 1px solid #dfdfdf;
  font-weight: 200;
  padding: 0.5rem 0;
  white-space: nowrap;
}

.validation-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.validation-error {
  color: red;
  font-size: 14px;
  text-align: center;
}

input[type="date"] {
  width: 100%;
}

.modal-mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.img-close {
  align-self: center;
  height: 30px;
}
.img-success {
  align-self: center;
  height: 5rem;
}

.icon-download {
  padding: 1rem;
  background-color: #ff9012;
  margin-left: auto;
}
.input-access-point {
  font-size: 15px;
  padding: 10px;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  left: 520px;
  top: 354px;
  background: #ffffff;
  border-radius: 4px;
}
.input-access-point-reference {
  font-size: 15px;
  padding: 10px;
  border: none;
  outline: none;
  width: 100%;
  height: 2rem;
  left: 520px;
  top: 354px;
  background: #ffffff;
}

.icon-rtl {
    background: url("../../../assets/img/search.png") no-repeat right;
    background-position: 95%;
}
</style>

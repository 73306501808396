export default {
  user(state, user) {
    state.user = user;
  },
  token(state, token) {
    state.token = token ? `JWT ${token}` : null;
  },
    isAdmin(state, isAdmin) {
    state.isAdmin = isAdmin;
    },
    profiles(state, profiles) {
    state.profiles = profiles;
    },
  deleteProfile(state, id){
    state.profiles = state.profiles.filter( profile => profile.id !== id )
  }

};

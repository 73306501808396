<template>
  <section class="card">
    <slot> </slot>
  </section>
</template>
<style scoped>
.card {
  margin: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.11);
}
</style>
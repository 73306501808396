export default {
  building(state, building) {
    state.building = building;
  },
  accessPoint(state, accessPoint) {
    state.accessPoint = accessPoint;
  },
  editAccessPoint(state, accessPoint) {
    const idx = state.accessPoint.map(e => e.id).indexOf(accessPoint.id);
    state.accessPoint[idx] = accessPoint;
  },
  deleteAccessPoint(state, id) {
    state.accessPoint = state.accessPoint.filter(access => access.id !== id);
  },
  addAccessPoint(state, accessPoint) {
    state.accessPoint.push(accessPoint);
  },
  indexBuildingState(state, indexBuildingState) {
    state.indexBuildingState = indexBuildingState;
  }

};

export default {
  user(state) {
    return state.user;
  },
  token(state) {
    return state.token;
  },
  isAuth(state) {
    return !!state.token?.length;
  },
  isAdmin(state) {
    return state.isAdmin;
  },
  profiles(state) {
    return state.profiles;
  }
};

<template>
  <base-icon
    icon-name="download"
    :width="width"
    :height="height"
    viewBox="0 0 25 25"
    :iconColor="color"
  >
      <path d="M23.4087 22.3184H1.59094C0.936483 22.3184 0.5 22.7546 0.5 23.4093C0.5 24.0638 0.936237 24.5002 1.59094 24.5002H23.4081C24.0625 24.5002 24.499 24.064 24.499 23.4093C24.499 22.7546 24.0628 22.3184 23.4083 22.3184H23.4087Z"
            fill="white"/>
      <path d="M11.7347 20.0268C11.953 20.245 12.2801 20.3542 12.4983 20.3542C12.7165 20.3542 13.0437 20.245 13.2619 20.0268L21.0074 12.2813C21.4436 11.8451 21.4436 11.1904 21.0074 10.7541C20.5712 10.3179 19.9165 10.3179 19.4802 10.7541L13.5895 16.6448V1.59094C13.5895 0.93648 13.1533 0.5 12.4986 0.5C11.8441 0.5 11.4076 0.936234 11.4076 1.59094V16.6448L5.51697 10.7541C5.08073 10.3179 4.42602 10.3179 3.98978 10.7541C3.55354 11.1904 3.55354 11.8451 3.98978 12.2813L11.7347 20.0268Z"
            fill="white"/>
    </base-icon>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";
export default {
  name: "icon-download",
  props: {
    size: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: "#FF9012",
    },
  },
  components: {
    BaseIcon,
  },
  computed: {
    width() {
      return 25 * this.size;
    },
    height() {
      return 25 * this.size;
    },
  },
};
</script>

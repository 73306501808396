import { logger } from "../../../utils/logger";

export default {
  async validateShopper({ rootGetters, commit }, code) {
    try {
      if (!code?.length) return;
      const url =
        "v2/residential_complex_security/security_guard/verify_shopper_dynamic_code";
      const dataModel = {
        verification_code: code
      };
      const { data } = await rootGetters["network/axios"].post(url, dataModel);
      commit("shopper", data["data"]);
    } catch (err) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.status === 404 ) {
        let message =  errorResponse.data.message === 'The specified shopper does not exist' ? 'El código ingresado no es correcto, por favor ingrésalo nuevamente.' : errorResponse.data.message;
        commit('validationCodeShopper', message)
      }
      throw Error(err);
    }
  },
  async reportShopper({ rootGetters, getters }, payload) {
    try {
      let url;
      if (payload["guardId"]) {
        url = "v2/residential_complex_security/security_guard/send_comment_security_guard";
      } else {
        url = "v2/residential_complex_security/security_guard/send_comment";
      }
      const data = {
        residential_complex_id:
          rootGetters["user/user"][
            "security_guard_current_residential_complex"
          ],
        comment: payload["comment"]
      };
      if (payload["shopperId"]) {
        data.shopper_id = payload["shopperId"];
      }
      if (payload["shopperAvailable"]) {
        data.shopper_id = payload["shopperAvailable"];
      }
      if (payload["shopperId"]) {
        data.shopper_id = payload["shopperId"];
      }
      if (payload["guardId"]) {
        data.security_guard_id = payload["guardId"];
      }
      await rootGetters["network/axios"].post(url, data);
    } catch (err) {
      logger("REPORT_SHOPPER", err);
      throw err;
    }
  },

  async getListShopper({ rootGetters, commit }, payload) {
    try {
      const url = `v2/list_shopper_security_guard_verify_residential_complex/${payload["residential_complex_id"]}`;
      const { data } = await rootGetters["network/axios"].get(url);
      commit("listShopper", data);
    } catch (err) {
      throw Error(err);
    }
  },
  async checkOut({ rootGetters }, payload) {
    try {
      const url = `v2/check_out_shopper_security_guard_verify_residential_complex/`;
      const data = {
        residentialcomplexshopperentry_id: payload["shopper_entry_id"]
      };
      await rootGetters["network/axios"].put(url, data);
      this.showSuccessAlert = true;
    } catch (err) {
      throw err.response.status;
    }
  }
};

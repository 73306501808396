<template>
  <div class="shopper-profile-header">
    <the-return-button
      class="back-button"
      :onPressed="returnHome"
    ></the-return-button>
  </div>

  <div class="shopper-profile-info">
    <div class="content-profile">
      <div class="card-shopper">
        <h3 class="title">INFORMACIÓN DE SHOPPER</h3>
        <user-photo
          :photo="shopperPhoto"
          :isLarge="true"
          :hasBorder="true"
          :borderColor="colorState"
        ></user-photo>
        <div class="image-profile-active">
          <img src="@/assets/svg/scude.svg" alt="" />
        </div>
        <p class="label-name">Nombre :</p>
        <p class="shopper-name-txt">{{ shopperName }}</p>
        <p><b class="subtitle">CI:</b> {{ shopperCI }} &emsp;</p>
        <p><b class="subtitle">Telf:</b> {{ shopperPhone }}</p>
        <p v-if="shopperUniformCode?.length > 0" class="shopper-uniform-code">
          <b v-if="shopperUniformCode?.length > 0" class="subtitle"
            >Codigo de uniforme:</b
          >
          {{ shopperUniformCode }}
        </p>
        <teleport to="body">
          <alert
            title="ATENCIÓN"
            @click="closeAlert"
            body="Este shopper está inactivo, nuestro equipo de Care Team se contactara de inmediato contigo."
            v-if="isInactiveAlert"
          ></alert>
        </teleport>
      </div>

      <div class="button-drawbacks">
        <the-report-case :shopper-available="shopperAvailable" :shopper-id="shopperId" />
      </div>
    </div>

    <div class="card-data-orders scroll-order">
      <h3 class="title">PEDIDOS POR ENTREGAR</h3>
      <template v-for="order in orders" :key="order">
        <the-order-details :order="order" />
      </template>
    </div>

    <div class="card-vehicle">
      <h3 class="title">DATOS DE VEHÍCULOS</h3>
      <shopper-car-data></shopper-car-data>
    </div>
  </div>
</template>

<script>
import UserPhoto from "@/components/UserPhoto.vue";
import TheReturnButton from "@/components/UI/buttons/TheReturnButton.vue";
import ShopperCarData from "./ShopperCarData.vue";
import Alert from "@/components/UI/alerts/Alert.vue";
import TheOrderDetails from "./TheOrderDetails.vue";
import TheReportCase from "@/views/shopperProfile/components/TheReportCase.vue";

export default {
  name: "ShopperProfileData",
  components: {
    TheReportCase,
    UserPhoto,
    TheReturnButton,
    ShopperCarData,
    Alert,
    TheOrderDetails
  },
  data() {
    return {
      isInactiveAlert: false
    };
  },
  computed: {
    shopperPhoto() {
      return this.$store.state.shopper.shopper
        ? this.$store.state.shopper.shopper.shopper_picture
        : "";
    },
    isActive() {
      return this.$store.state.shopper.shopper
        ? this.$store.state.shopper.shopper.shopper_available
        : false;
    },
    colorState() {
      return this.isActive ? "#CFDE00" : "#C7C7C7";
    },
    shopperName() {
      return this.$store.state.shopper.shopper
        ? this.$store.state.shopper.shopper.shopper_name
        : "Sin nombre";
    },
    shopperCI() {
      return this.$store.state.shopper.shopper
        ? this.$store.state.shopper.shopper.shopper_document
        : "Sin cédula";
    },
    shopperPhone() {
      return this.$store.state.shopper.shopper
        ? this.$store.state.shopper.shopper.shopper_phone
        : "Sin teléfono";
    },
    shopperId() {
      return this.$store.state.shopper.shopper.shopper_id
        ? this.$store.state.shopper.shopper.shopper_id
        : 0;

    },
    shopperAvailable() {
      return this.$store.state.shopper.shopper.shopper_available
          ? this.$store.state.shopper.shopper.shopper_available
          : false;

    },
    shopperUniformCode() {
      return this.$store.state.shopper.shopper
        ? this.$store.state.shopper.shopper.shopper_clothes_code
        : "Sin código de uniforme";
    },
    orders() {
      return this.$store.state.shopper.shopper
        ? this.$store.state.shopper.shopper.orders ?? []
        : [];
    }
  },
  methods: {
    closeAlert() {
      this.isInactiveAlert = false;
    },
    showInactiveAlert() {
      this.isInactiveAlert = !this.isActive();
    },
    returnHome() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.shopper-profile-info {
  padding-top: 25px;

  display: flex;

  justify-content: center;
}
p {
  margin: 0.25rem;
  color: #606060;
  align-self: start;
}
.subtitle {
  font-weight: 600;
  color: #606060;
}
.shopper-profile-header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50px);
}
.shopper-profile-info {
  padding-top: 25px;
  margin-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.title {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  color: #606060;
}
.card-shopper {
  margin: 0.5rem;
  padding: 1rem;
  display: flex;
  width: 20rem;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px #606060;
}
.card-data-orders {
  margin: 0.5rem;
  padding: 1rem;
  display: flex;
  height: 15rem;
  width: 23rem;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px #606060;
}
.card-vehicle {
  margin: 0.5rem;
  padding: 1rem;
  display: flex;
  height: 13rem;
  width: 20rem;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px #606060;
}

.shopper-name-txt {
  font-size: 20px;
  font-weight: 600;
  color: #606060;
  align-self: start;
}
.label-name {
  font-size: 1rem;
  font-weight: 600;
  color: #606060;
  align-self: start;
}
.scroll-order {
  height: 12rem;
  line-height: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
  gap: 1rem;
  max-height: 12rem;
}
@media (max-width: 600px) {
  .card-data-orders {
    max-width: 20rem;
    width: 100%;
    height: 85%;
  }
}

.content-profile {
  flex-direction: row;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.button-drawbacks {
  margin-top: 0.25rem;
  width: 80%;
  margin-left: 2rem;
}
.image-profile-active {
  position: absolute;
  bottom: 2rem;
  float: left;
  margin-left: 8rem;
  top: 35%;
}
.back-button {
  margin-left: 2rem;
}
</style>

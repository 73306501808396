<template>
  <base-icon
    :icon-name="label"
    :width="width"
    :height="height"
    viewBox="0 0 39 40"
    :iconColor="color"
  >
    <path
      d="M28.3035 0.430786H10.6014C7.83154 0.435284 5.17639 1.53761 3.21778 3.49622C1.25917 5.45483 0.156841 8.10998 0.152344 10.8799L0.152344 28.582C0.15857 31.3507 1.26166 34.0042 3.22008 35.9614C5.17851 37.9186 7.83267 39.02 10.6014 39.0245H28.3035C31.0711 39.0183 33.7236 37.9161 35.6806 35.9591C37.6376 34.0021 38.7398 31.3496 38.7461 28.582V10.8799C38.7416 8.11111 37.6401 5.45695 35.6829 3.49852C33.7257 1.5401 31.0723 0.437012 28.3035 0.430786V0.430786ZM36.4893 28.582C36.4869 30.7522 35.6237 32.8329 34.0891 34.3675C32.5545 35.9021 30.4738 36.7653 28.3035 36.7678H10.6014C8.43117 36.7653 6.35049 35.9021 4.81588 34.3675C3.28127 32.8329 2.41806 30.7522 2.41564 28.582V10.8799C2.41806 8.70961 3.28127 6.62893 4.81588 5.09432C6.35049 3.55971 8.43117 2.6965 10.6014 2.69408H28.3035C30.4738 2.6965 32.5545 3.55971 34.0891 5.09432C35.6237 6.62893 36.4869 8.70961 36.4893 10.8799V28.582Z"
    />
    <path
      d="M19.4574 9.16779C17.3698 9.16779 15.3291 9.78683 13.5933 10.9466C11.8576 12.1064 10.5047 13.7549 9.7058 15.6836C8.90692 17.6123 8.69789 19.7346 9.10516 21.782C9.51243 23.8295 10.5177 25.7102 11.9939 27.1864C13.47 28.6625 15.3507 29.6678 17.3982 30.0751C19.4457 30.4823 21.568 30.2733 23.4966 29.4744C25.4253 28.6756 27.0738 27.3227 28.2336 25.5869C29.3934 23.8511 30.0125 21.8104 30.0125 19.7228C30.0094 16.9244 28.8963 14.2415 26.9175 12.2627C24.9387 10.2839 22.2558 9.1709 19.4574 9.16779ZM19.4574 28.0133C17.8174 28.0133 16.2143 27.527 14.8507 26.6159C13.4872 25.7048 12.4244 24.4098 11.7968 22.8947C11.1692 21.3795 11.005 19.7123 11.325 18.1039C11.6449 16.4954 12.4346 15.018 13.5942 13.8584C14.7539 12.6987 16.2313 11.909 17.8398 11.5891C19.4482 11.2692 21.1154 11.4334 22.6305 12.0609C24.1456 12.6885 25.4406 13.7513 26.3517 15.1149C27.2629 16.4785 27.7492 18.0816 27.7492 19.7215C27.7481 21.9208 26.8744 24.0298 25.3197 25.5854C23.7651 27.141 21.6567 28.0161 19.4574 28.0185V28.0133Z"
    />
    <path
      d="M30.258 5.42896C29.6412 5.42896 29.0382 5.61187 28.5253 5.95458C28.0124 6.29729 27.6126 6.78439 27.3766 7.3543C27.1405 7.9242 27.0788 8.5513 27.1991 9.15631C27.3194 9.76132 27.6165 10.3171 28.0527 10.7532C28.4888 11.1894 29.0446 11.4865 29.6496 11.6068C30.2546 11.7272 30.8817 11.6654 31.4516 11.4293C32.0215 11.1933 32.5086 10.7935 32.8513 10.2806C33.194 9.76771 33.3769 9.1647 33.3769 8.54784C33.3762 7.72087 33.0474 6.92797 32.4627 6.34322C31.8779 5.75846 31.085 5.42965 30.258 5.42896V5.42896ZM30.258 9.40345C30.0888 9.40345 29.9234 9.35327 29.7827 9.25926C29.642 9.16524 29.5323 9.03161 29.4676 8.87527C29.4028 8.71893 29.3859 8.5469 29.4189 8.38093C29.4519 8.21496 29.5334 8.0625 29.653 7.94284C29.7727 7.82318 29.9252 7.74169 30.0911 7.70868C30.2571 7.67567 30.4291 7.69261 30.5855 7.75737C30.7418 7.82213 30.8754 7.93179 30.9694 8.0725C31.0635 8.2132 31.1137 8.37862 31.1137 8.54784C31.1133 8.77466 31.023 8.99209 30.8627 9.15247C30.7023 9.31285 30.4848 9.4031 30.258 9.40345V9.40345Z"
    />
  </base-icon>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";
export default {
  name: "icon-instagram",
  props: {
    size: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: "#ff9012",
    },
    iconName: {
      type: String,
    },
  },
  computed: {
    width() {
      return 39 * this.size;
    },
    height() {
      return 40 * this.size;
    },
    label() {
      return this.iconName ?? "";
    },
  },
  components: {
    BaseIcon,
  },
};
</script>

<template>
  <main-header></main-header>
  <the-return-button
    class="back-button"
    :onPressed="returnHomeAdmin"
  ></the-return-button>
  <form class="form-building" @submit.prevent="buildingEdit">
    <h1 class="title-building">Editar Unidad Habitacional o Condominio</h1>
    <div class="container-inputs">
      <div class="form">
        <base-input
          label="U. Habitacional o condominio:"
          :placeholder="name ?? 'Nombre del edificio'"
          :isDisabled="false"

          v-model="name"
        ></base-input>
        <base-input
          label="RUC:"
          :placeholder="document ?? 'RUC'"
          :isDisabled="false"
          :type="number"
          v-model="document"
        ></base-input>
        <base-input
          label="Calle principal:"
          :placeholder="main_street ?? 'Calle principal'"
          :isDisabled="false"
          v-model="main_street"
        ></base-input>
        <base-input
          label="Calle secundaria:"
          :placeholder="secondary_street ?? 'Calle secundaria'"
          :isDisabled="false"
          v-model="secondary_street"
        ></base-input>
        <base-input
          label="Numeración:"
          :placeholder="house_number ?? 'Numeración'"
          :isDisabled="false"
          autocomplete="Numeración"
          v-model="house_number"
        ></base-input>

        <base-input
          label="Teléfono entrada:"
          :placeholder="entrance_phone ?? 'Teléfono'"
          :isDisabled="false"
          :type="number"
          autocomplete="Teléfono"
          :value="entrance_phone"
          v-model="entrance_phone"
        ></base-input>
      </div>
      <div class="form">
        <base-input
          label="Teléfono administración:"
          :placeholder="administration_phone ?? 'Teléfono'"
          :isDisabled="false"
          :type="number"
          autocomplete="Teléfono"
          v-model="administration_phone"
        ></base-input>
        <base-input
          label="Número de casas o departamentos:"
          :placeholder="number_apartments ?? 'Número de casas o departamentos'"
          :isDisabled="false"
          :type="number"
          v-model="number_apartments"
        ></base-input>
        <base-input
          label="Compañia de seguridad:"
          :placeholder="security_company_name ?? 'Compañia de seguridad'"
          :isDisabled="false"
          v-model="security_company_name"
        ></base-input>

        <base-input
          label="Software de administración:"
          :placeholder="administration_software ?? 'Software de administración'"
          :isDisabled="false"
          autocomplete="Software de administración"
          v-model="administration_software"
        ></base-input>
      </div>
    </div>
    <base-button class="button-create-building" txt="Guardar"></base-button>
  </form>
  <teleport to="body">
    <alert
      @click="showSuccessAlert = false"
      backgroundColor="transparent"
      body="Se ha editado el edificio con éxito"
      v-if="showSuccessAlert"
      isSuccess
    ></alert>
  </teleport>
  <main-footer />
</template>
<script>
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import MainFooter from "@/views/auth/MainFooter.vue";
import TheReturnButton from "@/components/UI/buttons/TheReturnButton.vue";
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import BaseInput from "@/components/UI/inputs/BaseInput.vue";
import Alert from "@/components/UI/alerts/Alert.vue";

export default {
  name: "edit-building",
  data() {
    return {
      id: this.$route.params.id,
      name: this.$route.params.name,
      document: this.$route.params.document,
      main_street: this.$route.params.main_street,
      secondary_street: this.$route.params.secondary_street,
      entrance_phone: this.$route.params.entrance_phone,
      administration_phone: this.$route.params.administration_phone,
      house_number: this.$route.params.house_number,
      number_apartments: this.$route.params.number_apartments,
      security_company_name: this.$route.params.security_company_name,
      administration_software: this.$route.params.administration_software,
      isLoading: false,
      isDisable: false,
      showSuccessAlert: false,
      errorMessage: "",
      number: "number"
    };
  },

  components: {
    Alert,
    BaseInput,
    BaseButton,
    TheReturnButton,
    MainFooter,
    MainHeader
  },
  methods: {
    returnHomeAdmin() {
      this.$router.push({ name: "HomeAdmin" });
    },
    async buildingEdit() {
      try {
        if (this.isLoading || this.isDisable) return;
        this.isLoading = true;
        await this.$store.dispatch("building/updateBuilding", {
          id: this.id,
          name: this.name,
          document: this.document,
          main_street: this.main_street,
          secondary_street: this.secondary_street,
          entrance_phone: this.entrance_phone,
          administration_phone: this.administration_phone,
          house_number: this.house_number,
          number_apartments: this.number_apartments,
          security_company_name: this.security_company_name,
          administration_software: this.administration_software
        });
        this.showSuccessAlert = true;

        this.errorMessage =
          "Ingresa por lo menos un nombre y un apellido. Ej: German Caicedo";
          setTimeout(() => {
              this.showSuccessAlert = true;
              this.$router.push({ name: "HomeAdmin" });
          }, 2000);
      } catch (statusCode) {
        if (statusCode === 409)
          this.errorMessage =
            "Este usuario ya existe. Por favor, comunicate con Care Team si no recuerdas tu contraseña de ingreso.";
        else
          this.errorMessage =
            "Verifica que todos los campos esten llenos correctamente y vuelve a intentarlo.";
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style scoped>
.title-building {
  font-weight: 600;
  font-size: 2rem;
  align-self: center;
  color: #ff9012;
}
.form {
  align-items: center;
  justify-content: center;
  width: 30%;
  margin: 0 auto;
}
.container-inputs {
  display: flex;
  flex-direction: row;
  padding: 3rem 5rem;
}
.button-create-building {
  margin-bottom: 1rem;
}
.btn-disabled-buildings {
  background-color: #c7c7c7;
  color: #fff;
  cursor: unset;
}
.form-building {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 25rem;
}

.back-button {
  margin-left: 2rem;
}
</style>

<template>
  <div class="car-content">
    <div v-for="(car, i) in cars" :key="car">
      <div class="car-date__text">
        <div class="circle-car"></div>
        <h4 class="car-subtitle">Datos del vehículo {{ i + 1 }}:</h4>
      </div>
      <p>{{ car.vehicle_type }}</p>
      <p>Placa: {{ car.vehicle_plate }}</p>
      <div class="car-color">
        <p>Color:</p>
        <div
          v-if="car.vehicle_color"
          class="car-color__palette"
          :style="{ 'background-color': car.vehicle_color }"
        ></div>
        <p v-else>No asignado</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopperCarData",
  computed: {
    cars() {
      return this.$store.state.shopper.shopper == null
        ? []
        : this.$store.state.shopper.shopper.vehicles ?? [];
    }
  }
};
</script>

<style scoped>
p {
  color: #606060;
  margin: 0.25rem;
}
.car-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}
@media (max-width: 300px) {
  .car-content {
    grid-template-columns: 1fr;
  }
}

.car-subtitle {
  font-weight: 600;
  color: #606060;
  white-space: nowrap;
  margin: 0 12px;
}
.car-color__palette {
  border-radius: 50%;
  border: 2px solid #000;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.car-color {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.circle-car {
  height: 15px;
  width: 15px;
  margin: 0 3px 0 0;
  border-radius: 50%;
  background-color: #ff9012;
}
.car-date__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>

<template>
  <base-icon
    icon-name="tipti-logo"
    :width="width"
    :height="height"
    :viewBox="`0 0 230 130`"
    :iconColor="color"
  >
    <path
      d="M76.7347 40.6121C77.551 36.7345 73.4694 31.6325 68.3673 32.6529C63.0612 33.6733 58.5714 34.2856 57.551 39.3876C56.3265 44.2856 74.6939 49.5917 76.7347 40.6121Z"
    />
    <path
      d="M124.49 39.3879C123.265 38.7756 113.469 38.5715 109.184 42.6532C105.918 45.7144 100.204 56.7348 95.5102 66.7348C98.7755 55.5103 101.429 47.143 102.041 45.1021C103.061 41.0205 97.551 42.0409 93.6735 42.6532C91.0204 43.0613 86.1225 54.4899 82.0408 68.9797C81.6327 68.9797 81.6327 69.3879 81.4286 69.5919C80 72.245 68.5715 90.8164 63.6735 93.6736C62.8572 90.2042 62.0408 83.8777 64.6939 73.0613C65.7143 68.7756 67.7551 62.6532 68.5714 58.9797C69.7959 53.8777 57.347 52.4491 56.3266 56.7348C55.7143 59.1838 53.6735 63.6736 50.8163 75.3062C48.7755 84.4899 45.9184 103.061 59.5919 100.612C65.9184 99.5919 76.3266 81.8368 79.5919 76.3266C73.0612 101.837 69.5919 131.837 82.2449 129.592C87.551 128.572 80.8163 124.49 86.7347 98.7756C87.9592 93.4695 89.3878 88.1634 90.6123 83.0613C95.3061 73.4695 108.163 46.1226 114.694 45.1021C118.367 44.4899 109.592 89.3879 96.7347 91.6328C90.8164 92.6532 95.9184 95.3062 102.041 94.2858C124.898 90.2042 135.918 45.5103 124.49 39.3879Z"
    />
    <path
      d="M161.633 101.429C148.98 103.47 150.408 87.9594 153.673 75.5104C156.735 63.8777 158.98 59.3879 159.592 56.939C160.816 52.4492 172.857 54.49 171.633 59.592C170.612 63.2655 168.571 69.3879 167.347 73.6736C164.49 84.49 164.898 90.8165 165.714 94.49C165.714 94.49 167.755 100.408 161.633 101.429Z"
    />
    <path
      d="M178.776 45.5104C179.796 41.6329 175.918 36.5308 170.816 37.1431C165.51 37.9594 161.225 38.3676 159.796 43.4696C158.367 48.5717 176.327 54.49 178.776 45.5104Z"
    />
    <path
      d="M93.0612 101.429C93.0612 101.429 156.122 134.082 185.714 87.551C185.714 87.551 188.571 81.8368 193.469 77.7551C200.408 72.0408 208.776 79.7959 203.469 88.3674C190.816 109.184 148.98 138.776 93.0612 101.429Z"
    />
    <path
      d="M182.041 11.8369C177.551 11.8369 171.02 12.4491 163.673 13.8777C164.694 11.0205 165.51 8.77563 165.714 7.55114C167.347 0.000118658 155.918 -3.87743 153.878 5.91849C153.469 7.55114 151.837 11.6328 149.592 17.143C146.735 17.9593 147.551 17.7552 138.163 21.0205C138.163 21.0205 126.735 25.9185 124.49 30.8164C124.49 30.8164 123.265 35.3062 128.98 33.0613C138.163 29.1838 138.98 29.1838 145.51 27.3471C140.408 40.6124 133.673 57.3471 130.612 71.2246C125.714 93.0614 132.653 103.061 138.98 101.837C145.306 100.612 136.735 100.204 142.449 73.4695C145.306 60.0001 156.327 33.6736 160 23.6736C169.592 21.6328 178.776 20.6124 184.286 20.6124C187.959 20.6124 190.816 21.4287 190.612 20.0001C190.816 17.3471 186.531 11.8369 182.041 11.8369Z"
    />
    <path
      d="M95.5102 4.0819C93.0612 1.63292 90.2041 0.816596 86.9388 0.612514C86.3265 0.612514 85.9184 0.612514 85.3061 0.612514C77.551 0.408433 69.7959 1.837 62.449 3.46966C51.6327 5.71456 29.1837 12.8574 11.6327 25.3064C5.30612 29.7962 1.63265 36.1227 0.408163 40.6125C-0.204082 43.0615 -0.204082 45.5105 0.204081 47.9594C0.612245 50.6125 1.83673 52.6533 3.87755 54.286C4.89796 55.1023 7.34694 55.5105 7.95918 53.8778C8.57143 52.4492 6.73469 51.6329 6.12245 50.8166C5.10204 49.5921 5.30612 47.7554 5.91837 46.3268C7.14286 43.8778 9.79592 42.0411 11.8367 40.4084C18.1633 35.7146 24.898 31.4288 32.0408 27.7554C27.7551 46.1227 21.4286 71.6329 20.4082 87.5513C20.2041 91.6329 20 95.5105 22.2449 98.3676C23.2653 99.5921 24.4898 100.408 26.1224 100.817C28.3673 101.225 32.2449 100.817 33.4694 98.7758C34.6939 96.5309 33.6735 93.8778 34.4898 87.5513C34.898 81.837 35.5102 76.5309 36.3265 71.0207C38.5714 54.286 44.2857 34.286 47.1429 23.6737L48.1633 20.8166C57.9592 17.3472 67.9592 14.0819 78.3673 12.6533C82.0408 12.2452 85.7143 12.0411 89.1837 12.4492C91.0204 12.6533 92.8571 12.8574 94.6939 13.4697C95.7143 13.8778 97.3469 14.286 97.9592 12.8574C98.9796 9.59211 97.551 6.3268 95.5102 4.0819Z"
    />
  </base-icon>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";
export default {
  name: "icon-tipti-logo",
  props: {
    size: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: "#FFF",
    },
  },
  components: {
    BaseIcon,
  },
  computed: {
    width() {
      return 230 * this.size;
    },
    height() {
      return 130 * this.size;
    },
  },
};
</script>

<template>
  <base-icon
    icon-name="user"
    :width="width"
    :height="height"
    viewBox="0 0 23 23"
    :iconColor="color"
  >
    <path
      d="M11.112 0.8172C8.92067 0.8172 6.77852 1.46702 4.95646 2.68447C3.13441 3.90193 1.71429 5.63235 0.875686 7.65691C0.0370869 9.68147 -0.182327 11.9092 0.245187 14.0585C0.672702 16.2078 1.72794 18.182 3.27747 19.7315C4.827 21.281 6.80123 22.3363 8.95049 22.7638C11.0997 23.1913 13.3275 22.9719 15.3521 22.1333C17.3766 21.2947 19.107 19.8746 20.3245 18.0525C21.542 16.2305 22.1918 14.0883 22.1918 11.8969C22.1886 8.95939 21.0202 6.14308 18.9431 4.06592C16.8659 1.98875 14.0496 0.820399 11.112 0.8172ZM16.4404 13.9064C15.6916 13.3828 14.8696 12.9727 14.0008 12.6897C14.7909 12.0856 15.3717 11.2491 15.6616 10.2978C15.9516 9.34656 15.936 8.32831 15.6172 7.38631C15.2983 6.44432 14.6922 5.62596 13.8841 5.04633C13.076 4.4667 12.1065 4.15497 11.112 4.15497C10.1175 4.15497 9.14806 4.4667 8.33995 5.04633C7.53183 5.62596 6.92572 6.44432 6.60688 7.38631C6.28803 8.32831 6.27249 9.34656 6.56243 10.2978C6.85237 11.2491 7.4332 12.0856 8.22325 12.6897C5.98228 13.4222 4.10211 14.9766 2.96138 17.04C2.04033 15.5817 1.52735 13.9031 1.47594 12.1792C1.42453 10.4552 1.83656 8.74898 2.66907 7.23847C3.50159 5.72796 4.72409 4.46845 6.20912 3.59128C7.69414 2.71411 9.38729 2.2514 11.112 2.2514C12.8368 2.2514 14.5299 2.71411 16.015 3.59128C17.5 4.46845 18.7225 5.72796 19.555 7.23847C20.3875 8.74898 20.7996 10.4552 20.7481 12.1792C20.6967 13.9031 20.1838 15.5817 19.2627 17.04C18.575 15.7938 17.6105 14.7221 16.4434 13.9074L16.4404 13.9064Z"
    />
  </base-icon>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";
export default {
  name: "icon-user",
  props: {
    size: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: "#FFF",
    },
  },
  components: {
    BaseIcon,
  },
  computed: {
    width() {
      return 23 * this.size;
    },
    height() {
      return 23 * this.size;
    },
  },
};
</script>

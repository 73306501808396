<template>
  <div class="base-text-input">
    <h4 class="base-text-input__label" v-if="label != null">{{ label }}</h4>
    <input
      class="base-text-input__input"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :type="type"
      :maxlength="setMaxLength"
      @input="updateValue"
      :disabled="isDisabled"
    />
    <p v-if="showError">{{ errorText }}</p>
  </div>
</template>
<script>
export default {
  name: "base-text-input",
  data() {
    return {
      showError: this.isError,
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  computed: {
    setMaxLength() {
      return this.type === 'email' ? 150 :  this.maxLength
    }
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    errorText: {
      type: String,
      default: "Este campo es obligatorio",
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
     default: 35,
    },
  },
};
</script>
<style scoped>
.base-text-input {
  margin: 0.5rem 0;
}
.base-text-input__label {
  font-weight: 600;
  font-size: 14px;
  margin: 0.5rem 0;
  color: #606060;
}
.base-text-input__input {
  background-color: #fff;
  color: gray;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 0.7rem 1.2rem;
  width: 100%;
  font-family: inherit;
}

.base-text-input__input:focus {
  outline: none;
  color: gray;
}

.base-text-input__input::placeholder {
  color: #DFDFDF;
}

@media (min-width: 600px) {
  .base-text-input__input {
    font-size: 14px;
  }
}
</style>

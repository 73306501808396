<template>
  <main-header />
    <the-return-button class="profile__return" :onPressed="switchToProfile" />
    <main class="profile" >
        <edit-profile  v-if="isEditing" :onPressed="switchToProfile"></edit-profile>
        <profile v-else @on-change-password="switchToEdit"></profile>
    </main>
  <main-footer />
</template>
<script>
import Profile from "./components/Profile.vue";
import EditProfile from "./components/EditProfile.vue";
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import MainFooter from "@/views/auth/MainFooter";
import TheReturnButton from "@/components/UI/buttons/TheReturnButton";
import {mapGetters} from "vuex";

export default {
  name: "GuardProfile",
  data() {
    return { isEditing: false };
  },
  components: { MainFooter, Profile, EditProfile, MainHeader,    TheReturnButton, },
  computed: {
      ...mapGetters({
          isAdmin: "user/isAdmin"
      }),
    securityGuardPhoto() {
      return this.$store.state.user?.user?.security_guard_picture;
    },
  },
  methods: {
    switchToEdit() {
      this.isEditing = true;
    },
   async switchToProfile() {
        if (this.isEditing) {
            this.isEditing = false;
        } else {
            this.isAdmin
                ? await this.$router.push("/home-admin")
                : await this.$router.push("/home");
        }
    },
  },
};
</script>
<style scoped>
.profile {
    display: grid;
    place-items: center;
  margin-bottom: 2rem;
}
.profile__return {
    margin-left: 2rem;
}
</style>

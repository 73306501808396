<template>
  <div class="date-range-dialog" >
    <div class="dialog-overlay" @click="$emit('close-dialog')"></div>
    <div class="dialog-content">
      <div class="header">
        <h2>{{ title }}</h2>
        <img
          class="img-close"
          @click="$emit('close-dialog')"
          src="@/assets/svg/ic_close.svg"
          alt="cerrar modal"

        />
      </div>
      <p class="center-text">Esta operación es irreversible</p>

      <div class="buttons-content">
        <base-button
          class="btn-access-point-cancel"
          @click.prevent="closeDialog"
          txt="Cancelar"
        >
        </base-button>
        <base-button
          class="btn-access-point "
          @click.prevent="deleteGuard(idGuard)"
          txt="Eliminar"
        >
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import {mapActions} from "vuex";

export default {
  name: "ModalGuardProfile",
  data(){
      return{
          closeDialogAlertModal: true,
        deleteGuardBuilding: true,
      }
  },

  components: {
    BaseButton
  },

  props: {
    title: {
      type: String,
      default: "¿ Esta seguro que desea eliminar este registro ?"
    },

    idGuard: {
      type: Number,
      default: 0
    },
      closeDialogAlert:{
          type: Boolean,
          default: false
      }
  },

  methods: {

    async EditAccessPoint() {
      this.showRequestError = false;
      if (!this.main_street || !this.secondary_street || !this.reference) {
        this.showValidationError = true;
        return;
      }
      this.showValidationError = false;

      let payload = {
        main_street: this.main_street,
        secondary_street: this.secondary_street,
        reference: this.reference,
        id: this.id
      };
      try {
        await this.$store.dispatch(
          "building/updateBuildingAccessPoint",
          payload
        );
      } catch (error) {
        this.showRequestError = true;
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
        this.closeDialogAlertModal= false;
      this.$emit("close-dialog");
    },
      deleteGuard(index) {
          this.$store.dispatch("user/deleteBuildingGuard", {
              id: index
          });
          this.showSuccessAlert = true;
          this.closeDialogAlertModal= false;
          this.textAlert = "Punto de acceso eliminado correctamente";
          this.$emit("close-dialog");
          this.$emit("delete-guard",this.deleteGuardBuilding);


      },
  }
};
</script>

<style scoped />

<style scoped>
.date-range-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.dialog-content {
  width: 500px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 2.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

h2 {
  margin: 0;
  text-align: center;
  font-size: 15px;
  font-weight: 200;
  color: #ff9012;
  flex-grow: 1;
}
.buttons-content {
  flex-direction: row;
}
.img-close {
  align-self: center;
  height: 30px;
}

.instruction-label {
  text-align: center;
  padding: 0.25rem;
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 200;
  color: #ff9012;
}

.date-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 0 1rem 0;
  width: 100%;
}

.date-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}
hr.solid {
  width: 100%;
  border-top: 1px solid #bbb;
}
.date-label {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #404040;
}

.btn-access-point {
  color: #ffffff;
  border: 1px solid #dfdfdf;
  font-weight: 200;
  width: 30%;
  height: 10%;
  white-space: nowrap;
}

.btn-access-point-cancel {
  color: #ffffff;
  border: 1px solid #dfdfdf;
  font-weight: 200;
  width: 30%;
  height: 10%;
  white-space: nowrap;
  background-color: #606060;
}

.validation-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.validation-error {
  color: red;
  font-size: 14px;
  text-align: center;
}

input[type="date"] {
  width: 100%;
}

.modal-mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.img-close {
  align-self: center;
  height: 30px;
}
.img-success {
  align-self: center;
  height: 5rem;
}

.icon-download {
  padding: 1rem;
  background-color: #ff9012;
  margin-left: auto;
}
.input-access-point {
  font-size: 15px;
  padding: 10px;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  left: 520px;
  top: 354px;
  background: #ffffff;
  border-radius: 4px;
}
.input-access-point-reference-edit {
  font-size: 15px;
  padding: 10px;
  border: none;
  outline: none;
  width: 100%;
  height: 2rem;
  left: 520px;
  top: 354px;
  background: #ffffff;
}
.center-text {
  text-align: center;
}
</style>

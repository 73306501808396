<template>
  <base-icon
    :icon-name="label"
    :width="width"
    :height="height"
    viewBox="0 0 39 33"
    :iconColor="color"
  >
    <path
      d="M15.1909 0.87538C15.1755 0.857395 15.16 0.842013 15.142 0.82403C13.9984 -0.306867 12.1119 -0.268278 10.9351 0.906142L10.5624 1.28134C10.5573 1.28643 10.5495 1.28903 10.5444 1.29424L8.86357 2.97507C8.85848 2.98016 8.85588 2.98797 8.85067 2.99306L0.922341 10.919L0.919738 10.9216C0.876079 10.9678 0.842593 11.0244 0.832301 11.0886L0.0587321 15.324L0.00478015 15.617C-0.0132044 15.7224 0.0201617 15.8277 0.0947043 15.9049C0.156468 15.9666 0.238698 16 0.323534 16C0.344121 16 0.362105 15.9974 0.382693 15.9949L3.04013 15.5092L4.91113 15.1674C4.94201 15.1623 4.97277 15.1494 5.00105 15.1365C5.00614 15.1339 5.01134 15.1288 5.01643 15.1262C5.03702 15.1133 5.05761 15.0979 5.07559 15.0826C5.07819 15.08 5.08068 15.08 5.08068 15.0775L13.0272 7.13612L14.708 5.45529L15.1012 5.05692C16.2653 3.89016 16.3039 2.01916 15.1911 0.875422L15.1909 0.87538ZM2.80575 14.8922L0.726634 15.2727L1.35881 11.8133L4.18581 14.6403L2.80575 14.8922ZM1.60813 11.1478L9.09725 3.66143L10.4851 5.0493L3.00115 12.5409L1.60813 11.1478ZM4.85155 14.3888L3.45858 12.9959L10.945 5.50946L12.3379 6.90243L4.85155 14.3888ZM12.7956 6.44747L9.55221 3.20405L10.7755 1.98074L14.0189 5.22416L12.7956 6.44747ZM14.6434 4.59708C14.6409 4.59968 14.6434 4.59968 14.6434 4.59708L14.4764 4.76414L11.2329 1.52587L11.3923 1.36401C12.315 0.441353 13.7928 0.405399 14.6896 1.28947C14.6973 1.29976 14.7076 1.31006 14.7153 1.32035C15.5943 2.2095 15.5609 3.67964 14.6434 4.59709L14.6434 4.59708Z"
      fill="#FF9012"
    />
  </base-icon>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";
export default {
  name: "icon-pencil",
  props: {
    size: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: "#ff9012"
    },
    iconName: {
      type: String
    }
  },
  computed: {
    width() {
      return 39 * this.size;
    },
    height() {
      return 30 * this.size;
    },
    label() {
      return this.iconName ?? "";
    }
  },
  components: {
    BaseIcon
  }
};
</script>

import Axios from "axios";

const baseURL =
  process.env.VUE_APP_API_ENV === "DEV"
    ? process.env.VUE_APP_API_DEV_URL
    : process.env.VUE_APP_API_ENV === "STG"
    ? process.env.VUE_APP_API_STG_URL
    : process.env.VUE_APP_API_PROD_URL;

const authRequest = (token, baseURL) => {
  return {
    baseURL,
    headers: {
      Authorization: token,
    },
  };
};

export default {
  axios(_, __, ___, rootGetters) {
    const customization = authRequest(rootGetters["user/token"], baseURL);
    return Axios.create(customization);
  },
};

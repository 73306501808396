<template>
  <form class="form-register" @submit.prevent="registerUser">
    <h2>Regístrate</h2>
    <p class="register__legend">
      Ingresa tus credenciales para acceder a crear los edificios y los guardias
      de seguridad.
    </p>
    <div class="inputs-container">
      <base-text-input
        v-model.trim="username"
        label="Nombre y Apellido:"
        placeholder="Ingresa tus nombres completos"
      />
      <base-text-input
        v-model.trim="identityDocument"
        label="Documento de identidad:"
        placeholder="Ingresa documento de identidad"
      />
      <base-text-input
        v-model.trim="email"
        label="Correo electrónico:"
        placeholder="Ingresa tu Email"
        type="email"
      />
      <base-text-input
        v-model.trim="password"
        label="Contraseña:"
        placeholder="Ingresa tu contraseña"
        type="password"
      />
      <p class="error" v-if="errorMessage.length">{{ errorMessage }}</p>
    </div>
    <footer class="register__footer">
      <loading v-if="isLoading"></loading>
      <text-button-solid v-else :is-disable="isDisable" text="Regístrate" />
      <p>O</p>
      <p class="register__footer_text" @click="$emit('switch-form')">
        Iniciar sesión
      </p>
    </footer>
  </form>

  <teleport to="body">
    <alert
      title="Registro exitoso!"
      @click="closeAlert"
      body="Tu usuario se registró existosamente. Pronto un agente de Tipti se comunicará contigo para verificar los datos ingresados."
      v-if="showSuccessAlert"
      :isSuccess="true"
    />
  </teleport>
</template>

<script>
import Loading from "@/components/UI/loading/Loading.vue";
import Alert from "@/components/UI/alerts/Alert.vue";
import BaseTextInput from "@/components/UI/inputs/BaseInput";
import TextButtonSolid from "@/components/UI/buttons/TextButtonSolid";

export default {
  name: "the-register",
  data() {
    return {
      username: "",
      email: "",
      password: "",
      identityDocument: "",
      errorMessage: "",
      isLoading: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      showWrongUsername: false,
      showAlreadyUserAlert: false
    };
  },
  emits: ["switchForm"],
  components: {
    TextButtonSolid,
    BaseTextInput,
    Loading,
    Alert
  },
  watch: {
    username() {
      this.errorMessage = "";
    },
    email() {
      this.errorMessage = "";
    },
    password() {
      this.errorMessage = "";
    },
    identityDocument() {
      this.errorMessage = "";
    }
  },
  computed: {
    isDisable() {
      return (
        !this.username.length ||
        !this.email.length ||
        !this.password.length ||
        !this.identityDocument.length
      );
    }
  },
  methods: {
    closeAlert() {
      if (this.showSuccessAlert) {
        this.$emit("switch-form");
      }
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
      this.showWrongUsername = false;
      this.showAlreadyUserAlert = false;
    },
    async registerUser() {
      try {
        if (this.isLoading || this.isDisable) return;
        this.isLoading = true;
        const fullName = this.username.split(" ");
        if (fullName?.length > 1) {
          const firstName = fullName[0];
          const lastName = fullName[1];
          await this.$store.dispatch("user/registerAdmin", {
            first_name: firstName,
            last_name: lastName,
            document: this.identityDocument,
            email: this.email.toLowerCase(),
            password: this.password
          });
          this.showSuccessAlert = true;
        } else {
          this.errorMessage =
            "Ingresa por lo menos un nombre y un apellido. Ej: German Caicedo";
        }
      } catch (statusCode) {
        if (statusCode === 409)
          this.errorMessage =
            "Este usuario ya existe. Por favor, comunicate con Care Team si no recuerdas tu contraseña de ingreso.";
        else
          this.errorMessage =
            "Verifica que todos los campos esten llenos correctamente y vuelve a intentarlo.";
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.form-register {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 25rem;
}
.register__legend {
  margin: 0;
  font-size: 14px;
  width: 100%;
}
.inputs-container {
  display: flex;
  margin: 1rem 2rem 3rem;
  flex-direction: column;
}
h2 {
  font-size: 62px;
  margin: 0;
  color: #ff9012;
  font-weight: 600;
}
p {
  color: #606060;
  font-size: 12px;
  align-self: center;
}
.register__footer {
  display: flex;
  flex-direction: column;
}
.register__footer_text {
  color: #ff9012;
  align-self: center;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
}
.error {
  margin: 0;
  color: #c90808;
  font-size: 12px;
  text-align: justify;
}
@media (max-width: 600px) {
  .form-register {
    min-width: unset;
    justify-items: center;
    align-items: center;
    justify-content: center;
  }
  h2 {
    font-size: 30px;
    width: 100%;
  }
  .register__footer_text {
    margin-bottom: 1rem;
  }
  .inputs-container {
    margin: 0;
    width: 100%;
  }
}
</style>

<template>
  <div class="date-range-dialog">
    <div class="dialog-overlay" @click="$emit('close-dialog')"></div>
    <div class="dialog-content">
      <div class="header">
        <h2>{{ title }}</h2>
        <img
          class="img-close"
          @click="$emit('close-dialog')"
          src="@/assets/svg/ic_close.svg"
          alt="cerrar modal"
        />
      </div>

      <div class="date-row">
        <div class="date-col">
          <label class="instruction-label"
            >Calle primaria:</label
          >
          <input
            class="input-access-point  icon-rtl"
            :placeholder="main_street ?? 'Ingresa la calle primaria'"
            type="text"
            v-model="main_street"
            maxlength="98"
          />
        </div>
        <div class="date-col">
          <label class="instruction-label"
            >Calle secundaria:</label
          >
          <input
            class="input-access-point icon-rtl"
            :placeholder="secondary_street ?? 'Ingresa la calle secundaria'"
            type="text"
            v-model="secondary_street"
            maxlength="98"
          />
        </div>
        <div class="date-col">
          <label class="instruction-label">Referencia:</label>
          <input
            class="input-access-point-reference-edit"
            :placeholder="reference ?? 'Edificio, piso, departamento'"
            type="text"
            v-model="reference"
            maxlength="98"
          />
          <hr class="solid" />
        </div>
      </div>
      <div class="validation-row">
        <span v-if="showValidationError" class="validation-error">
          Por favor, Ingrese punto de acceso y referencia.
        </span>
        <span v-if="showRequestError" class="validation-error">
          Uups, algo salio mal, intentalo nuevamente en un momento.
        </span>
      </div>
      <base-button
        class="btn-access-point "
        @click.prevent="EditAccessPoint"
        txt="Editar punto"
      >
      </base-button>
    </div>
  </div>
  <teleport to="body">
    <alert
      @click="showSuccessAlert = false"
      backgroundColor="transparent"
      :body="textAlert"
      v-if="showSuccessAlert"
      isSuccess
    ></alert>
  </teleport>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import { mapGetters } from "vuex";
import Alert from "@/components/UI/alerts/Alert.vue";

export default {
  name: "EditAccessPointBuilding",
  data() {
    return {
      main_street: this.accessPoint.main_street,
      secondary_street: this.accessPoint.secondary_street,
      reference: this.accessPoint.reference,
      id: this.accessPoint.id,
      showValidationError: false,
      showSuccessAlert: false,
      showRequestError: false,
      textAlert: "Punto de acceso editado correctamente"
    };
  },
  components: {
    Alert,
    BaseButton
  },
  computed: {
    ...mapGetters({
      accessPoint: "building/accessPoint"
    }),
    accessPointBuilding() {
      return this.$store.state.building.accessPoint;
    }
  },
  props: {
    title: {
      type: String,
      default: "Editar registro"
    },
    building: {
      type: Number,
      default: 0
    },
    accessPoint: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    async EditAccessPoint() {
      this.showRequestError = false;
      if (!this.main_street || !this.secondary_street || !this.reference) {
        this.showValidationError = true;
        return;
      }
      this.showValidationError = false;

      let payload = {
        main_street: this.main_street,
        secondary_street: this.secondary_street,
        reference: this.reference,
        id: this.id
      };
      try {
        await this.$store.dispatch(
          "building/updateBuildingAccessPoint",
          payload
        );
          this.showSuccessAlert = true;
        setTimeout(() => {
          this.$emit("close-dialog");
            this.showSuccessAlert = false;
        }, 2000);
      } catch (error) {
        this.showRequestError = true;
      } finally {
        this.isLoading = false;
      }
    }
  },
    emits: ["close-dialog"]
};
</script>



<style lang="css" scoped>
.date-range-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.dialog-content {
  width: 500px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 2.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

h2 {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 200;
  color: #ff9012;
  flex-grow: 1;
}

.img-close {
  align-self: center;
  height: 30px;
}

.instruction-label {
  text-align: center;
  padding: 0.25rem;
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 200;
  color: #ff9012;
}

.date-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 0 1rem 0;
  width: 100%;
}

.date-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}
hr.solid {
  width: 100%;
  border-top: 1px solid #bbb;
}
.btn-access-point {
  color: #ffffff;
  border: 1px solid #dfdfdf;
  font-weight: 200;
  padding: 0.5rem 0;
  white-space: nowrap;
}

.validation-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.validation-error {
  color: red;
  font-size: 14px;
  text-align: center;
}

input[type="date"] {
  width: 100%;
}


.img-close {
  align-self: center;
  height: 30px;
}

.input-access-point {
  font-size: 15px;
  padding: 10px;
  border: 1px solid #c7c7c7;
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  left: 520px;
  top: 354px;
  background: #ffffff;
  border-radius: 4px;
}
.input-access-point-reference-edit {
  font-size: 15px;
  padding: 10px;
  border: none;
  outline: none;
  width: 100%;
  height: 2rem;
  left: 520px;
  top: 354px;
  background: #ffffff;
}
.icon-rtl {
    background: url("../../../assets/img/search.png") no-repeat right;
    background-position: 95%;
}
</style>

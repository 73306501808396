<template>
  <div class = "section-input">
    <p class="label-input">Código de Shopper:</p>
    <input
        class="input-qr__input"
        placeholder="Ingresa el código aquí"
        type="text"
        maxlength="10"
        @input="updateValue"
    />
    <p class="label-error" v-if="isValid">{{ errorTxt }}</p>
  </div>
</template>
<script>

export default {
  name: "base-input-qr",
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
  props: {
    errorTxt: {
      type: String,
      default: "Código incorrecto, por favor ingrésalo nuevamente.",
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style src="./base-input-qr.css" scoped/>

<template>
  <base-icon
    icon-name="key"
    :width="width"
    :height="height"
    :viewBox="`0 0 230 130`"
    :iconColor="color"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.00122998 50.0276C0.00122878 22.6975 22.1892 0.507801 49.521 0.5078C72.5211 0.507799 92.0012 16.2016 97.4144 37.578L181.295 37.578L181.295 10.2479L197.8 10.2479L197.8 26.7537L213.494 26.7537L213.494 10.2479L230 10.2479L230 62.4702L97.4132 62.4702C92.0016 83.8467 72.5186 99.5405 49.5198 99.5405C22.1897 99.5405 -9.69869e-07 77.3524 -2.16458e-06 50.0206L0.00122998 50.0276ZM24.8959 50.0276C24.8959 36.2268 35.9901 25.133 49.5194 25.133C63.3202 25.133 74.414 36.2272 74.414 50.0276C74.414 63.557 63.3198 74.6512 49.5194 74.6512C35.9901 74.6512 24.8959 63.557 24.8959 50.0276Z"
    />
  </base-icon>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";
export default {
  name: "icon-key",
  props: {
    size: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: "#FFF",
    },
  },
  components: {
    BaseIcon,
  },
  computed: {
    width() {
      return 230 * this.size;
    },
    height() {
      return 130 * this.size;
    },
  },
};
</script>

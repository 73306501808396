<template>
  <main-header/>
    <ListBuilding :key="buildings?.length" ></ListBuilding>
  <div class="house-text-img" v-if="buildings.length == 0">
    <img class="img-admin" src="@/assets/svg/residential.svg" alt="" />
    <p class="img-admin__slogan-residential">
      No tienes registrado ningún condominio o edificio.<br />
    </p>
    <p class="slogan-residential_b">
      Por favor, realiza la configuración
    </p>
  </div>
  <main-footer />
</template>

<script>
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import MainFooter from "@/views/auth/MainFooter.vue";
import ListBuilding from "@/views/HomeAdmin/components/ListBuilding.vue";
import {checkMobileBrowser} from "@/utils/utils.js";
import index, { mapGetters } from "vuex";

export default {
  name: "HomeAdmin",
  components: { ListBuilding, MainFooter, MainHeader },

  computed: {
      checkMobileBrowser() {
          return checkMobileBrowser();
      },
    ...mapGetters({
      isAuth: "user/isAuth"
    }),
    securityGuard() {
        return this.$store.state.user.user;
    },
      buildings() {
          return this.$store.state.building?.building ;
      }
  },
  async mounted() {
      await this.$store.dispatch("user/information");

  },

  methods: {
      createBuilding() {
      this.$router.push({ name: "CreateBuilding" });
    }
  }
};
</script>

<style scoped>
.house-text-img {
  align-self: center;
  align-content: center;
  transform: translateX(10%) translateY(-15%);
}
.img-admin {
  width: 50%;
  height: 50%;
  transform: translateX(50%);
  &__slogan-residential {
    align-self: center;
    margin: 1rem;
    justify-self: center;
    text-align: center;
  }
}
.slogan-residential_b {
  font-weight: 600;
  text-align: center;
}

@media (max-width: 600px) {
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
  margin: 1rem;
  width: 50%;
}
</style>

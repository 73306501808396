<template>
  <main-header />
  <div class="home">
    <p class="title">Buen día</p>
    <p class="text">
      Para verificar la identidad del shopper de entrega, por favor solicítale
      el código personal, o puedes escanear el QR.
    </p>
    <base-input-qr v-model.trim="code" :is-valid="showErrorAlert" :error-txt="shopper"  />
    <base-button
      class="margin"
      txt="Validar código"
      @click="validateShopper"
      :class="{ 'btn-disabled': code.length < 10 }"
    ></base-button>
    <qr-stream class="margin mv" @decode="onDecode" v-if="scannerOpen" />
    <base-button
      class="margin mv"
      :txt="scannerOpen ? 'Cerrar Camara' : 'Escanear QR'"
      v-if="checkMobileBrowser"
      @click="activateScanner"
    ></base-button>
    <div class="btn-scanner margin mv" v-else>
      <base-button
        is-out-line
        :is-orange="false"
        txt="Escoger Imagen"
      ></base-button>
      <qr-capture class="btn-scanner__hide" @detect="onDetect" />
    </div>

    <div class="img-shoppers" v-if="listShoppers.length > 0">
      <router-link class="list-shoppers" :to="{ name: 'list-profiles' }">
        <img src="@/assets/svg/shopper-list.svg" alt="" class="img" />
        <p >VER LISTA DE SHOPPERS</p>
      </router-link>
    </div>
    <div class="shopper-report">
      <the-report-case  :guard-id="securityGuard?.security_guard_id"  />
    </div>
  </div>

  <main-footer />
  <teleport to="body">
    <alert
      title="Descarga exitosa!"
      @click="showReportDialogSuccess = false"
      body="Descarga exitosa."
      v-if="showReportDialogSuccess"
      :isSuccess="true"
    />
  </teleport>

</template>

<script>

import { checkMobileBrowser } from "@/utils/utils.js";
import { QrStream, QrCapture } from "vue3-qr-reader";
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import BaseInputQR from "@/components/UI/inputs/baseInputQR/BaseInputQR.vue";
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import MainFooter from "@/views/auth/MainFooter";

import { logger } from "../utils/logger";
import Alert from "../components/UI/alerts/Alert.vue";
import { mapGetters } from "vuex";
import TheReportCase from "@/views/shopperProfile/components/TheReportCase.vue";



export default {
  data() {
    return {
      code: "",
      showErrorAlert: false,
      scannerOpen: false,
      showDownloadReport: false,
      showReportDialogSuccess: false
    };
  },
  watch: {
    code(newText, oldText) {
      if (newText === oldText && !this.showErrorAlert) return;
      this.showErrorAlert = false;
    }
  },
  components: {
    TheReportCase,
    Alert,
    MainFooter,
    BaseButton,
    MainHeader,
    "base-input-qr": BaseInputQR,
    QrStream,
    QrCapture
  },
  computed: {
    checkMobileBrowser() {
      return checkMobileBrowser();
    },
    ...mapGetters({
      isAuth: "user/isAuth"
    }),
    securityGuard() {
      return this.$store.state.user.user;
    },
    listShoppers() {
      return this.$store.state.shopper.listShopper;
    },
   shopper() {
     return this.$store.state.shopper?.validationCodeShopper;
   },

  },
  methods: {
    closeAlert() {
      this.showErrorAlert = false;
    },
    activateScanner() {
      this.scannerOpen = !this.scannerOpen;
    },
    handleCloseDialog(value) {
      this.showDownloadReport = false;
      if (!value) return;
      logger("Descarga de reporte exitosa", "success");
      this.showReportDialogSuccess = true;
    },
    async onDecode(code) {
      this.code = code;
      this.scannerOpen = false;
      await this.validateShopper();
    },
    async onDetect(promise) {
      this.showErrorAlert = false;
      try {
        const { content } = await promise;
        if (content === null) {
          this.showErrorAlert = true;
        } else {
          this.code = content;
          this.scannerOpen = false;
          await this.validateShopper();
        }
      } catch (error) {
        this.showErrorAlert = true;
      }
    },
    async validateShopper() {
      try {
        this.showErrorAlert = false;
        if (this.code?.length < 10) return;
        await this.$store.dispatch("shopper/validateShopper", this.code);
        if (this.$store.getters["shopper/shopper"])
          await this.$router.push({ name: "Shopper" });
      } catch (err) {
        this.showErrorAlert = true;
      }
    },
    getListShopper() {
      this.$store.dispatch("shopper/getListShopper", {
        residential_complex_id: this.$store.state.user?.user
            ?.security_guard_current_residential_complex
      });
    },
  },
  created() {
    this.$store.commit("shopper/shopper", null);
  },
  async mounted() {
    await this.$store.dispatch("user/information");
    this.getListShopper();
  }
};
</script>

<style scoped>
.margin {
  margin-top: 2rem;
}

.mv {
  margin-bottom: 2rem;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  text-align: center;
  color: #ff9012;
  margin-bottom: 1rem;
}

.title {
  font-style: normal;
  font-weight: 650;
  font-size: 50px;
  text-align: center;
  color: #ff9012;
  margin: 2rem;
}

@media only screen and (max-width: 600px) {
  .text {
    margin: 10px;
    font-size: 15px;
  }
}

.btn-scanner__hide {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  top: 1rem;
  margin: 0;
  bottom: 0;
  width: 100%;
}

.btn-scanner {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1rem;
}

.btn-disabled {
  background-color: #c7c7c7;
  color: #fff;
  cursor: unset;
}
.list-shoppers {
  text-decoration: unset;
  color: #606060;
  font-size: 14px;
  padding: 1rem;
  justify-content: center;
  width: fit-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.img-shoppers {
  align-self: end;
  @media only screen and (max-width: 600px) {
    align-self: center;
    padding: 0 1rem;


  }
}
.shopper-report{
  align-self: start;
  padding: 0 15rem;
  transform: translateY(-250%);
  @media only screen and (max-width: 600px) {
    align-self: center;
    padding: 0 1rem;
    transform: translateY(-50%);

  }
}
</style>
<template>
  <base-card
    class="card-profile"
    v-for="(profile, index) in profilesGuards"
    :key="index"
  >
    <div class="profile-guard-building">
      <img
        :src="profile?.profile_picture || require('@/assets/svg/profileimg.svg')"
        class="img-round"
      />
      <img  v-if=" profile?.profile_picture == 'https://statics-dev-stg.tipti.market/undefined'"
          :src=" require('@/assets/svg/profileimg.svg')"
          class="img-round"
      />

      <div >
        <div >
          <p
            class="title-guard-information  "

            @click="editBuildingGuard(profile)"
          >
          {{ limitText(profile?.name,15) }}  {{ profile?.last_name }}

            <span
              class="title-guard-information-span cursor-pointer"
              @click="editBuildingGuard(profile)"
              ><icon-pencil
                class="icons cursor-pointer"
                @click="editBuildingGuard(profile)"
              ></icon-pencil
              >Editar información</span
            >
          </p>

          <p class="title-guard-information-email">
            {{ profile?.email }}

            <span
              class="title-guard-information-span cursor-pointer"
              @click="closeAlert(profile.id)"
              ><icon-delete
                class="icons cursor-pointer"
                @click.prevent="closeAlert(profile.id)"
              ></icon-delete
              >Eliminar guardia</span
            >
          </p>
        </div>
      </div>
    </div>
  </base-card>
  <ModalGuardProfile
    v-if="showSuccessAlert"
    :idGuard="idGuard"
    @close-dialog="showSuccessAlert = false"
    @delete-guard="deleteGuard = true"
  ></ModalGuardProfile>
  <teleport to="body">
    <alert
        @click="deleteGuard = false"
        backgroundColor="transparent"
        :body="textAlert"
        v-if="deleteGuard"
        isSuccess
    ></alert>
  </teleport>
</template>

<script>
import BaseCard from "@/components/UI/card/BaseCard.vue";
import IconPencil from "@/components/icons/IconPencil.vue";
import IconDelete from "@/components/icons/IconDelete.vue";
import ModalGuardProfile from "@/views/guardProfile/components/ModalGuardProfile.vue";
import Alert from "@/components/UI/alerts/Alert.vue";

export default {
  name: "DetailGuardProfileBuilding",
  data() {
    return {
      profileGuardEdit: [],
      deleteGuard: false,
      showSuccessAlert: false,
      textAlert: "",
      idGuard: 0
    };
  },
  components: {Alert, ModalGuardProfile, IconDelete, IconPencil, BaseCard },
  props: {
    profilesGuards: {
      type: Object
    },

  },
  emits: ["on-change-password"],
  mounted() {
    this.activeIconGuard();
  },
  methods: {
    logout() {
      this.$store.commit("user/token", null);
      this.$router.push("/");
    },

    editBuildingGuard(profile) {
      this.$router.push({
        name: "editSecurityGuard",
        params: {
          id: profile.id,
          name: profile.name,
          document: profile.document,
          phone: profile.phone,
          email: profile.email,
          profile_picture: profile.profile_picture,
          security_company: profile.security_company,
          enabled: profile.enable
        }
      });
    },
    activeIconGuard() {
      if (this.profilesGuards > 0) return (this.iconGuard = false);
    },
    closeAlert(idGuard) {
      this.idGuard = idGuard;
      this.showSuccessAlert = true;
      this.textAlert = "Se elimino guardia correctamente";


      setTimeout(() => {
        this.deleteGuard= false;
      }, 10000);
    },

    limitText(value, maxLength ){
      if(value.length > maxLength ){
        return value.slice(0, maxLength) + '...';
      }
      return value
    },

  }
};
</script>

<style scoped>
p {
  font-weight: 400;
  color: #606060;
}

span {
  font-weight: 600;
  color: #606060;
}
.card-profile {
  width: 200%;
  height: 20%;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
}

.profile-guard-building {
  display: flex;
  position: absolute;
  justify-content: center;
  box-sizing: content-box;
}

.title-guard-information {
  font-weight: 200;
  font-size: 1.5rem;
  align-self: start;
  transform: translateX(20%);

}
.title-guard-information-email {
  font-weight: 200;
  font-size: 1rem;
  align-self: start;
  transform: translateX(20%);

}
.title-guard-information-span {
  font-weight: 200;
  font-size: 1rem;
  text-decoration-line: underline;
  color: #ff9012;
  float: right;
  white-space: nowrap;
  margin-left: 3rem;
  align-self: center;
}
.icons {
  position: relative;
  margin: -1rem -1rem -1rem 2rem;

}
.img-round {
  width: 130px;
  height: 130px;
  position: absolute;
  border-radius: 150px;
  align-self: center;
  transform: translateX(-240%);
}


</style>

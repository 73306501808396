<template>
  <main-header />
  <div class="content">
    <shopper-profile-data />
  </div>
    <main-footer />
</template>

<script>
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import ShopperProfileData from "./components/ShopperProfileData.vue";
import MainFooter from "@/views/auth/MainFooter";


export default {
  components: {
    MainFooter,
    MainHeader,
    ShopperProfileData
  }
};
</script>
<style scoped>
.content {
  display: flex;
  align-items: center;
  flex-direction: column;

}
</style>

export default {
  shopper(state, shopper) {
    state.shopper = shopper;
  },
    listShopper(state, listShopper) {
    state.listShopper = listShopper;
    },
    validationCodeShopper(state, validationCodeShopper) {
    state.validationCodeShopper = validationCodeShopper;
    }
};

<template>
  <div class="modal-mask">
    <div class="modal-wrapper" v-if="showSuccessAlert">
      <section class="modal-container">
        <div class="modal-title">
          <div />
          <div />
          <img
            class="img-close"
            @click="onClose"
            src="@/assets/svg/ic_close.svg"
            alt="cerrar modal"
          />
        </div>
        <img class="img-success" src="@/assets/gif/success.gif" />
        <h3 class="msg-success">¡Reporte enviado con éxito!</h3>
      </section>
    </div>
    <form class="modal-wrapper" v-else @submit.prevent="sendComment">
      <section class="modal-container">
        <div class="modal-title">
          <div />
          <h2>Cuéntanos tu Inconveniente</h2>
          <img
            class="img-close"
            @click="onClose"
            src="@/assets/svg/ic_close.svg"
            alt="cerrar modal"
          />
        </div>
        <div class="coment-area">
          <textarea
            autofocus
            rows="8"
            v-model.trim="comment"
            placeholder="Escribe aquí.."
            maxlength="250"
          />
          <p class="coment-area__counter">{{ comment.length }}/250</p>
        </div>
        <loading v-if="isLoading" />
        <base-button
          v-else
          txt="Reportar"
          :class="{ 'btn-disable': isBtnReportDisable }"
        ></base-button>
      </section>
    </form>
  </div>
</template>
<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import Loading from "../../../components/UI/loading/Loading.vue";
export default {
  name: "report-case-modal",
  data() {
    return {
      isLoading: false,
      showSuccessAlert: false,
      comment: ""
    };
  },
  props: {
    onClose: {
      type: Function
    },
    shopperId:{
      type: Number,

    },
   guardId :{
      type: Number
   },
    shopperAvailable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BaseButton,
    Loading
  },
  computed: {
    isBtnReportDisable() {
      return !this.comment.length;
    }
  },
  methods: {
    closeAlert() {
      this.showSuccessAlert = false;
      this.onClose();
    },
    async sendComment() {
      try {
        if (this.isBtnReportDisable || this.isLoading) return;
        this.isLoading = true;
        await this.$store.dispatch("shopper/reportShopper", {
          shopperId: this.shopperId,
          comment: this.comment,
          shopperAvailable: this.shopperAvailable,
          guardId: this.guardId
        });
        this.showSuccessAlert = true;
      } catch (err) {
        this.$emit("on-error");
      } finally {
        this.isLoading = false;
      }
    }
  },
  emits: ["on-error"]
};
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.img-close {
  align-self: center;
  height: 30px;
}
.img-success {
  align-self: center;
  height: 5rem;
}
h2 {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: #606060;
}
textarea {
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
  background-color: #f5f5f5;
}
::placeholder {
  color: #dfdfdf;
}
.coment-area {
  display: flex;
  margin: 1rem 0 2rem;
  flex-direction: column;
}
.coment-area__counter {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #606060;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  text-align: -webkit-center;
}
.modal-container {
  border-radius: 5px;
  max-width: 50rem;
  padding: 1rem;
  background-color: #fff;
  transition: all 0.3s ease;
}

.modal-title {
  align-items: center;
  display: grid;
  grid-template-columns: 5% 1fr 5%;
}
.msg-success {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #ff9012;
}
</style>

<template>
  <div class="order-building-guard">
    <div
      v-if="showDetailBuilding && building !== null"
      class="container-list-building"
    >
      <p class="title-building-information">
        Información básica
        <span
          class="title-building-information-span"
          @click="editBuilding(building)"
          ><icon-pencil
            class="icon-pencil"
            @click="editBuilding(building)"
          />Editar
        </span>
      </p>
      <p>
        <b class="order-detail__info order-detail__info--tag">Nombre:</b>
        {{ building?.name }}
      </p>
      <p>
        <b class="order-detail__info order-detail__info--tag">RUC:</b>
        {{ building?.document }}
      </p>
      <p>
        <b class="order-detail__info order-detail__info--tag"
          >Calle principal:</b
        >
        {{ building?.main_street }}
      </p>
      <p>
        <b class="order-detail__info order-detail__info--tag"
          >Calle secundaria:</b
        >
        {{ building?.secondary_street }}
      </p>
      <p>
        <b class="order-detail__info order-detail__info--tag"
          >Numero de unidades:</b
        >
        {{ building?.number_apartments }}
      </p>
      <p>
        <b class="order-detail__info order-detail__info--tag"
          >Compañia de seguridad:</b
        >
        {{ building?.security_company_name }}
      </p>
      <p>
        <b class="order-detail__info order-detail__info--tag"
          >Software de administración:</b
        >
        {{ building?.administration_software }}
      </p>
      <p>
        <b class="order-detail__info order-detail__info--tag"
          >Punto de acceso:</b
        >
      </p>
      <base-button
        is-out-line
        :pathSvg="require('@/assets/svg/add.svg')"
        :is-orange="false"
        @click.prevent="showAccessPointBuilding = !showAccessPointBuilding"
        class="access-point"
        txt="&#160;Agregar punto de acceso"
      />
      <div
        class="container"
        v-for="(accessPoint, index) in accessPointBuilding"
        :key="index"
      >
        <p class="tooltip">
          <span class="text ">
            {{ limitText(accessPoint?.main_street,20)    }} -
            {{ limitText(accessPoint?.secondary_street,20) }}</span
          >
            <span class="tooltiptext"> {{accessPoint?.main_street  }} -{{accessPoint?.secondary_street }}</span>

          <span class="icon">
            <icon-pencil
              class="icon-pencil"
              @click="editAccessPoint(accessPoint)"
            ></icon-pencil>
            <icon-delete
              class="icon-pencil"
              @click="deleteModalAccessPoint(accessPoint.id)"
            ></icon-delete
          ></span>
        </p>
      </div>

      <base-button
        is-out-line
        :is-orange="false"
        @click.prevent="showDownloadReport = !showDownloadReport"
        class="button-create-building-report"
        txt="&#160;Descargar reporte"
      />
    </div>
    <div class="list-guard">
      <p class="title-building-information">
        Guardias de Seguridad
      </p>
      <detail-guard-profile-building
          :key="building?.id"
        :profilesGuards="profilesGuards"
      ></detail-guard-profile-building>
    </div>
    <div class="house-text-img" v-if="profilesGuards?.length  == 0">
      <img class="img-admin" src="@/assets/svg/userGuard.svg" alt="" />
      <p class="img-admin__slogan-residential">
        No tienes registrado ningún guardia.<br />
      </p>
      <p class="slogan-residential_b">
        Por favor, realiza la configuración
      </p>
    </div>
  </div>
  <download-report-in-dates
    v-if="showDownloadReport"
    :idBuilding="building.id"
    @close-dialog="handleCloseDialog"
  />
  <EditAccessPointBuilding
    v-if="editAccess"
    :accessPoint="accessPointBuildingEdit"
    @close-dialog="closeDialogEditModal"
  ></EditAccessPointBuilding>
  <AccessPointBuilding
    :building="building.id"
    v-if="showAccessPointBuilding"
    @close-dialog="closeDialog"
  ></AccessPointBuilding>

  <ModalDeleteItems
    v-if="deleteAccess"
    :title="titleDelete"
    :deleteItem="deleteAccessPoint"
    :index="accessPointBuildingDelete"
    @close-dialog="closeDialogDeleteModal"
  ></ModalDeleteItems>
  <teleport to="body">
    <alert
      @click="showSuccessAlert = false"
      backgroundColor="transparent"
      :body="textAlert"
      v-if="showSuccessAlert"
      isSuccess
    ></alert>
  </teleport>
</template>
<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import IconPencil from "@/components/icons/IconPencil.vue";
import { logger } from "@/utils/logger";
import DownloadReportInDates from "@/components/DownloadReportInDates.vue";
import AccessPointBuilding from "@/views/HomeAdmin/components/AccessPointBuilding.vue";
import EditAccessPointBuilding from "@/views/HomeAdmin/components/EditAccesPointBuilding.vue";
import IconDelete from "@/components/icons/IconDelete.vue";
import DetailGuardProfileBuilding from "@/views/guardProfile/components/detailGuardProfileBuilding.vue";
import Alert from "@/components/UI/alerts/Alert.vue";
import ModalDeleteItems from "@/views/HomeAdmin/components/ModalDeleteItems.vue";

export default {
  name: "detail-building",
  components: {
    ModalDeleteItems,
    Alert,
    DetailGuardProfileBuilding,
    IconDelete,
    EditAccessPointBuilding,
    AccessPointBuilding,
    DownloadReportInDates,
    IconPencil,
    BaseButton
  },

  data() {
    return {
      showDetailBuilding: true,
      showDownloadReport: false,
      showAccessPointBuilding: false,
      showReportDialogSuccess: false,
      accessPointBuildingEdit: Object,
      access: [],
      editAccess: false,
      showSuccessAlert: false,
      textAlert: "",
      iconGuard: true,
      deleteAccess: false,
      accessPointBuildingDelete: 0,
        active: false,
      titleDelete: "¿Estas seguro que desea eliminar punto de acceso?"
    };
  },

  props: {
    building: {
      required: true
    }
  },

  computed: {
    accessPointBuilding() {
      return this.$store.state.building.accessPoint;
    },
    profilesGuards() {
      return this.$store.state.user.profiles;
    }
  },
  async mounted() {
    await this.$store.dispatch("building/getBuildingAccessPoint", {
      id: this.building.id
    });
    await this.$store.dispatch("user/getProfilesGuards", {
      id: this.building.id
    });
    this.iconGuardProfile();
    await this.getAccessPointBuilding(this.building.id);
  },
  methods: {
    editBuilding(building) {
      if (building === null) return;

      this.$router.push({
        name: "edit-building",
        params: {
          id: building.id,
          name: building.name,
          document: building.document,
          main_street: building.main_street,
          secondary_street: building.secondary_street,
          entrance_phone: building.entrance_phone,
          administration_phone: building.administration_phone,
          house_number: building.house_number,
          number_apartments: building.number_apartments,
          security_company_name: building.security_company_name,
          administration_software: building.administration_software
        }
      });
    },
    editAccessPoint(accessPoint) {
      this.accessPointBuildingEdit = accessPoint;
      this.editAccess = true;

      this.textAlert = "Punto de acceso editado correctamente";
      setTimeout(() => {
        this.showSuccessAlert = false;
        this.$emit("close-dialog");
      }, 1000);
    },
    deleteModalAccessPoint(index) {
      this.accessPointBuildingDelete = index;
      this.deleteAccess = true;
    },
    deleteAccessPoint(index) {
      this.$store.dispatch("building/deleteBuildingAccessPoint", {
        id: index
      });

      this.showSuccessAlert = true;
      this.textAlert = "Punto de acceso eliminado correctamente";
      setTimeout(() => {
        this.showSuccessAlert = false;
        this.deleteAccess = false;
        this.accessPointBuilding.splice(index, 1);
      }, 1000);
    },
    async getAccessPointBuilding(id) {
      this.access = await this.$store.dispatch(
        "building/getBuildingAccessPoint",
        {
          id: id
        }
      );
    },
    iconGuardProfile() {
      this.$store.state.user.profiles.length > 0
        ? (this.iconGuard = false)
        : (this.iconGuard = true);
    },
    handleCloseDialog(value) {
      this.showDownloadReport = false;
      if (!value) return;
      logger("Descarga de reporte exitosa", "success");
      this.showReportDialogSuccess = true;
    },
    closeDialog() {
      this.showAccessPointBuilding = false;
    },
    closeDialogEditModal() {
      this.editAccess = false;
    },
    closeDialogDeleteModal() {
      this.deleteAccess = false;
    },
      limitText(value, maxLength ){
          if(value.length > maxLength ){
              return value.slice(0, maxLength) + '...';
          }
          return value
      }
  }
};
</script>
<style scoped>
.title-building-information {
  font-weight: 600;
  font-size: 2rem;
  align-self: start;
  color: #ff9012;
}
.title-building-information-span {
  font-weight: 200;
  z-index: 1;
  margin-right: 3rem;
  font-size: 1rem;
  text-decoration-line: underline;
  color: #ff9012;
}
.container-list-building {
  display: flex;
  flex-direction: column;
  align-items: start;

  margin: 1rem;
  padding: 1rem;
  border: none;
}
.icon-pencil {
  margin: -1rem -1rem -1rem 2rem;
}
.access-point {
  margin: 1rem 1rem 1rem;
  white-space: nowrap;
  width: 30%;
  height: 3rem;
  font-size: 1.25rem;
  color: #ff9012;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;
}
.button-create-building-report {
  margin: 1rem 1rem 1rem 1rem;
  white-space: nowrap;
  width: 40%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 600;
  color: #ff9012;
  border: 1px solid #ff9012;
  border-radius: 5px;
  background-color: #ffffff;
}
.order-building-guard {
  display: flex;
  flex-direction: row;
}
.list-guard {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 1rem 3rem 1rem 2rem;
  padding: 1rem;
}
.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 6rem;
}
.icon {
  color: #ff9012;
}
.house-text-img {
  align-self: center;
  align-content: center;
  transform: translateX(-80%) translateY(-15%);
}
.img-admin {
  width: 50%;
  height: 50%;
  transform: translateX(50%);
  &__slogan-residential {
    align-self: center;
    margin: 1rem;
    justify-self: center;
    text-align: center;
  }
}
.slogan-residential_b {
  font-weight: 600;
  text-align: center;
}
.tooltip {
    position: relative;
    display: inline-block;

}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 100%;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 3rem;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    right: 10%;
    word-break: break-all;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 15px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
</style>

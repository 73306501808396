<template>
  <main-header></main-header>
  <the-return-button class="back-button" :onPressed="returnHomeAdmin" />
  <div class="form-center">
    <form class="form-register" @submit.prevent="editUser">
      <h2>Editar Guardia</h2>
      <div class="inputs-container">
        <img  v-if="profilePicture != '' && profilePicture != 'https://statics-dev-stg.tipti.market/undefined'"
          :src=" previewImage ||profilePicture "
          class="img-round"
        />
        <img v-if="profilePicture === '' || profilePicture == 'https://statics-dev-stg.tipti.market/undefined'"
            :src=" previewImage || require('@/assets/svg/profileimg.svg')"
            class="img-round"
        />

        <label for="myFile" class="file-image">Subir fotografía</label>
        <input
          @change="handleFileChange"
          type="file"
          :v-model="profilePictureEdit"
          class="file-input"
          id="myFile"
          name="myFile"
          accept="image/png, image/jpeg"
        />

        <base-text-input
          v-model.trim="username"
          label="Nombre y Apellido:"
          :placeholder="username ?? 'Ingresa tus nombres completos'"
          :max-length="maxLength"
        />
        <base-text-input
          v-model.trim="identityDocument"
          label="Documento de identidad:"
          :placeholder="identityDocument ?? 'Ingresa documento de identidad'"
          :type="type"
          :max-length="maxlengthPhone"
        />
        <base-text-input
            v-model.trim="phone"
            label="Celular:"
            :placeholder="phone ?? 'Ingresa número celular'"
            :type="type"
            :max-length="maxlengthPhone"
        />
        <base-text-input
          v-model.trim="email"
          label="Correo electrónico:"
          :placeholder="email ?? 'Ingresa tu Email'"
          type="email"
          :autocomplete="email"
        />
        <base-text-input
          v-model.trim="password"
          type="password"
          label="Contraseña:"
          :placeholder="password ?? 'Ingresa tu contraseña'"
        />
        <base-text-input
          v-model.trim="securityCompany"
          label="Compañia de seguridad:"
          :placeholder="
            securityCompany ?? 'Ingresa el nombre de la compañiad de seguridad'
          "
        />
        <div class="check-text">
          <label>Habilitar Guardia&nbsp;</label>

          <input
            placeholder="Habilitar Guardia"
            class="check"
            v-model="enabledGuard"
            type="checkbox"
          />
        </div>
        <p class="error" v-if="errorMessage.length">{{ errorMessage }}</p>
      </div>
      <footer class="register__footer">
        <loading v-if="isLoading"></loading>
        <text-button-solid  text="Guardar" />
      </footer>
    </form>
  </div>
  <teleport to="body">
    <alert
      title="Registro exitoso!"
      @click="closeAlert"
      body="Tu usuario se actualizo existosamente."
      v-if="showSuccessAlert"
      :isSuccess="true"
    />
  </teleport>
  <main-footer />
</template>

<script>
import Loading from "@/components/UI/loading/Loading.vue";
import Alert from "@/components/UI/alerts/Alert.vue";
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import TheReturnButton from "@/components/UI/buttons/TheReturnButton.vue";
import BaseTextInput from "@/components/UI/inputs/BaseInput.vue";
import MainFooter from "@/views/auth/MainFooter.vue";
import TextButtonSolid from "@/components/UI/buttons/TextButtonSolid.vue";

export default {
  name: "editSecurityGuard",
  data() {
    return {
      username: this.$route.params.name,
      email: this.$route.params.email,
      password: this.$route.params.password,
      identityDocument: this.$route.params.document,
      phone: this.$route.params.phone,
      errorMessage: "",
      securityCompany: this.$route.params.security_company,
      profilePicture: this.$route.params.profile_picture ?? require('@/assets/svg/profileimg.svg'),
      profilePictureEdit: "",
      enabledGuard: this.$route.params.enabled,
      isLoading: false,
      type: "text",
      showSuccessAlert: false,
      showErrorAlert: false,
      showWrongUsername: false,
      showAlreadyUserAlert: false,
      guardProfileId: {},
      numberPhone:123456789,
      maxLength: 35,
      maxlengthPhone: 10,
      previewImage: null,
    };
  },
  emits: ["switchForm"],
  components: {
    TextButtonSolid,
    MainFooter,
    BaseTextInput,
    TheReturnButton,
    MainHeader,
    Loading,
    Alert
  },

  props: {
    profilesGuards: {
      type: Object,
      required: false
    }
  },
  methods: {
    returnHomeAdmin() {
      this.$router.push({ name: "HomeAdmin" });

    },
    closeAlert() {
      if (this.showSuccessAlert) {
        this.$emit("switch-form");
      }
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
      this.showWrongUsername = false;
      this.showAlreadyUserAlert = false;
    },
    handleFileChange(event) {
      this.profilePictureEdit = event.target.files[0];
      const file = event.target.files[0];

      if(file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file);
      }

    },
    async editUser() {
      try {

        this.isLoading = true;
        const fullName = this.username.split(" ");

        if (fullName?.length > 1 && this.phone.length >1) {
          const firstName = fullName[0];
          const lastName = fullName[1];
          await this.$store.dispatch("user/editProfileGuard", {
            first_name: firstName,
            last_name: lastName,
            document: this.identityDocument,
            phone: this.phone ?? this.numberPhone,
            email: this.email.toLowerCase(),
            profile_picture: this.profilePictureEdit,
            security_company: this.securityCompany,
            password: this.password,
            id: this.$route.params.id,
            enabled_guard: this.enabledGuard
          });
          this.showSuccessAlert = true;
        } else {
          this.errorMessage =
            "Ingresa por lo menos un nombre y un apellido o te falta el celular. Ej: German Caicedo";
        }
      } catch (statusCode) {
        if (statusCode === 400)
          this.errorMessage =
            "Verifica que todos los campos esten llenos correctamente y vuelve a intentarlo.";


        else
          this.errorMessage =
            "Verifica que todos los campos esten llenos correctamente y vuelve a intentarlo.";
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.form-center {
  align-self: center;
}
.form-register {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 25rem;
  padding: 0 0 2rem 0;
}
.register__legend {
  margin: 0;
  font-size: 14px;
  width: 100%;
}
.inputs-container {
  display: flex;
  margin: 1rem 2rem 3rem;
  flex-direction: column;
}
h2 {
  text-align: center;
  font-size: 2rem;
  margin: 0;
  color: #ff9012;
  font-weight: 600;
}
p {
  color: #606060;
  font-size: 12px;
  align-self: center;
}
.register__footer {
  display: flex;
  flex-direction: column;
}
.register__footer_text {
  color: #ff9012;
  align-self: center;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
}
.error {
  margin: 0;
  color: #c90808;
  font-size: 12px;
  text-align: justify;
}
@media (max-width: 600px) {
  .form-register {
    min-width: unset;
    justify-items: center;
    align-items: center;
    justify-content: center;
  }
  h2 {
    font-size: 30px;
    width: 100%;
  }
  .register__footer_text {
    margin-bottom: 1rem;
  }
  .inputs-container {
    margin: 0;
    width: 100%;
  }
}
.img-round {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  align-self: center;
}
.file-input {
  display: none;
}
.file-image {
  align-self: center;
  text-decoration-line: underline;
}
.check {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  accent-color: #ff9012;
}
.check-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.back-button {
  margin-left: 2rem;
}
</style>

<template>
  <main-header></main-header>
  <the-return-button
    class="back-button"
    :onPressed="returnHomeAdmin"
  ></the-return-button>
  <form class="form-building" @submit.prevent="buildingRegister" ref="anyName">
    <h1 class="title-building">Crear Unidad Habitacional o Condominio</h1>
    <div class="container-inputs">
      <div class="form">
        <base-input
          label="Unidad Habitacional o condominio*:"
          placeholder="Ingresa el nombre"
          :isDisabled="false"
          v-model="buildingName"
        ></base-input>
        <base-input
          label="RUC:"
          :type="number"
          placeholder="Ingresa el RUC en caso de tener uno"
          :isDisabled="false"
          v-model="ruc"
        ></base-input>
        <base-input
          label="Calle principal*:"
          placeholder="Ingresa la calle principal"
          :isDisabled="false"
          v-model="mainStreet"
        ></base-input>
        <base-input
          label="Calle secundaria*:"
          placeholder="Ingresa la calle secundaria"
          :isDisabled="false"
          v-model="secondaryStreet"
        ></base-input>
        <base-input
          label="Numeración*:"
          placeholder="Ingresa el número de domicilio"
          :isDisabled="false"
          autocomplete="hola"
          v-model="numberRing"
        ></base-input>
        <base-input
          label="Teléfono entrada*:"
          :type="number"
          placeholder="Ingresa el numero de teléfono"
          :isDisabled="false"
          v-model="phone"
        ></base-input>
      </div>
      <div class="form">
        <base-input
          label="Teléfono administración*:"
          :type="number"
          placeholder="Ingresa el numero de teléfono"
          :isDisabled="false"
          v-model="phoneAdmin"
        ></base-input>
        <base-input
          label="Numero de casas o departamentos*:"
          placeholder="Ingresa el numero total de casas o departamentos"
          :isDisabled="false"
          :type="number"
          v-model="numberHouse"
        ></base-input>
        <base-input
          label="Compañia de seguridad:"
          placeholder="Ingresa el nombre de la compañia"
          :isDisabled="false"
          v-model="companySecurity"
        ></base-input>

        <base-input
          label="Software de administración:"
          placeholder="Ingresa el nombre del software "
          :isDisabled="false"
          v-model="softwareAdmin"
        ></base-input>
      </div>
    </div>

    <text-button-solid
      class="button-create-building"
      :is-disable="isDisable"
      text="Guardar"
    />
    <loading v-if="isLoading"></loading>
  </form>
  <main-footer />
  <teleport to="body">
    <alert
      @click="closeAlert"
      backgroundColor="transparent"
      body="Se ha creado la Unidad Habitacional con éxito"
      v-if="showSuccessAlert"
      isSuccess
    ></alert>
  </teleport>
</template>
<script>
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import MainFooter from "@/views/auth/MainFooter.vue";
import TheReturnButton from "@/components/UI/buttons/TheReturnButton.vue";
import BaseInput from "@/components/UI/inputs/BaseInput.vue";
import Alert from "@/components/UI/alerts/Alert.vue";
import TextButtonSolid from "@/components/UI/buttons/TextButtonSolid.vue";
import Loading from "@/components/UI/loading/Loading.vue";

export default {
  name: "CreateBuilding",
  components: {
    Loading,
    TextButtonSolid,
    Alert,
    BaseInput,
    TheReturnButton,
    MainFooter,
    MainHeader
  },
  data() {
    return {
      buildingName: "",
      ruc: "",
      mainStreet: "",
      secondaryStreet: "",
      numberRing: "",
      phone: "",
      phoneAdmin: "",
      numberHouse: "",
      companySecurity: "",
      softwareAdmin: "",
      showSuccessAlert: false,
      number: "number",
      isLoading: false
    };
  },
  computed: {
    isDisable() {
      return (
        !this.buildingName.length ||
        !this.mainStreet.length ||
        !this.secondaryStreet.length ||
        !this.numberRing.length ||
        !this.phone.length ||
        !this.phoneAdmin.length ||
        !this.numberHouse.length

      );
    }
  },
  methods: {
    returnHomeAdmin() {
      this.$router.push({ name: "HomeAdmin" });
    },
    async buildingRegister() {
      try {
        if (this.isLoading || this.isDisable) return;
        this.isLoading = true;

        await this.$store.dispatch("building/registerBuilding", {
          name: this.buildingName,
          document: this.ruc,
          main_street: this.mainStreet,
          secondary_street: this.secondaryStreet,
          entrance_phone: this.phone,
          administration_phone: this.phoneAdmin,
          house_number: this.numberRing,
          number_apartments: this.numberHouse,
          security_company_name: this.companySecurity,
          administration_software: this.softwareAdmin
        });
        this.submitForm();
        this.showSuccessAlert = true;
        this.errorMessage =
          "Ingresa por lo menos un nombre y un apellido. Ej: German Caicedo";
        this.isLoading = true;
      } catch (statusCode) {
        if (statusCode === 409)
          this.errorMessage =
            "Este conjunto ya existe en la plataforma, intenta con otro.";
        else
          this.errorMessage =
            "Verifica que todos los campos esten llenos correctamente y vuelve a intentarlo.";
      } finally {
        this.isLoading = false;
      }
    },
    submitForm() {
      this.$refs.anyName.reset();
      setTimeout(() => {
      this.closeAlert();
      }, 2000);
    },
    closeAlert() {
      this.showSuccessAlert = false;
        this.$router.push({ name: "HomeAdmin" });
    }
  }
};
</script>
<style scoped>
.title-building {
  font-weight: 600;
  font-size: 2rem;
  align-self: center;
  color: #ff9012;
}
.form {
  align-items: center;
  justify-content: center;
  width: 30%;
  margin: 0 auto;
}
.container-inputs {
  display: flex;
  flex-direction: row;
  padding: 3rem 5rem;
}
.button-create-building {
  margin-bottom: 1rem;
}
.btn-disabled-buildings {
  background-color: #c7c7c7;
  color: #fff;
  cursor: unset;
}
.form-building {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 25rem;
}
.back-button {
  margin-left: 2rem;
}
</style>

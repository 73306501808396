import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
const state = {
  user: null,
  token: null,
  isAuth: false,
  isAdmin: false,
  profiles: null ,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

<template>
  <footer class="footer">
    <div class="footer_left">
      <img src="@/assets/img/footer_logo.svg" alt="Tipti logos" />
      <div class="social-links">
        <a href="https://www.twitter.com/tiptimarket">
          <icon-twitter class="social-icon" />
        </a>
        <a href="https://www.instagram.com/tipti.market">
          <icon-instagram class="social-icon" />
        </a>
        <a href="https://www.linkedin.com/company/tipti-s.a./">
          <icon-linked-in class="social-icon" />
        </a>
        <a href="https://wa.me/593995540223/?text=Hey,%20Tipti...">
          <icon-whatsapp class="social-icon" />
        </a>
      </div>
    </div>
    <div class="footer_right">
      <a href="https://tipti.com.ec/faqs" target="_blank">
        <img
          src="@/assets/img/footer_care_team.svg"
          alt="Tipti logos"
          class="care-team-link"
        />
      </a>
      <img src="@/assets/img/footer_numbers.svg" alt="Tipti logos" />
      <img src="@/assets/img/footer_tipti_direction.svg" alt="Tipti logos" />
    </div>
  </footer>
</template>
<script>
import IconInstagram from "@/components/icons/IconInstagram";
import IconLinkedIn from "@/components/icons/IconLinkedIn";
import IconTwitter from "@/components/icons/IconTwitter";
import IconWhatsapp from "@/components/icons/IconWhatsapp";

export default {
  name: "main-footer",
  components: {
    IconInstagram,
    IconLinkedIn,
    IconTwitter,
    IconWhatsapp,
  },
};
</script>
<style scoped>
.footer {
  margin-top: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(35%, auto));
  gap: 1rem;
  justify-items: center;
  padding: 2rem 1rem;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.11);
  background-color: white;
}

.footer_left > img {
  width: 100%;
  max-height: 110px;
}

.social-links {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.social-icon {
  margin: 1rem 0.8rem;
}

.footer_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.footer_right > img {
  width: 100%;
  margin: 0.5rem 0;
}

.care-team-link {
  max-width: 350px;
}

@media (max-width: 800px) {
  .footer {
    display: none;
  }
}
</style>

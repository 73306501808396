import Home from "@/views/Home.vue";
import Authentication from "@/views/auth/Authentication.vue";
import GuardProfile from "@/views/guardProfile/GuardProfile.vue";
import ShopperProfile from "@/views/shopperProfile/ShopperProfile.vue";
import store from "@/store";
import HomeAdmin from "@/views/HomeAdmin/HomeAdmin.vue";
import CreateBuilding from "@/views/HomeAdmin/components/create-building.vue";
import editBuilding from "@/views/HomeAdmin/components/EditBuilding.vue";
import registerSecurityGuard from "@/views/guardProfile/components/RegisterSecurityGuard.vue";
import editSecurityGuard from "@/views/guardProfile/components/EditDetailGuardProfile.vue";
import listProfiles from "@/views/shopperProfile/ListProfiles.vue";

const _authGuard = (_, __, next) => {
  if (store.getters["user/isAuth"]) {
    next();
  } else {
    next({ name: "Authentication" });
  }
};

const _autoLoginGuard = (_, __, next) => {
  if (store.getters["user/isAuth"]) {
   store.getters["user/isAdmin"] ? next({ name: "HomeAdmin" }) : next({ name: "Home" });
  } else {
    next();
  }
};

const _shopperInfoGuard = (_, __, next) => {
  if (!store.getters["user/isAuth"]) {
    return next({ name: "Authentication" });
  }
  if (store.getters["shopper/shopper"]) {
    next();
  } else {
    next({ name: "Home" });
  }
};

const routes = [
  {
    path: "/",
    name: "Authentication",
    component: Authentication,
    beforeEnter: _autoLoginGuard,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: _authGuard,
  },
  {
    path: "/shopper-list",
    name: "list-profiles",
    component: listProfiles,
    beforeEnter: _authGuard,
  },

  {
    path: "/home-admin",
    name: "HomeAdmin",
    component: HomeAdmin,
    beforeEnter: _authGuard,
  },
  {
    path: "/guard-profile",
    name: "GuardProfile",
    component: GuardProfile,
    beforeEnter: _authGuard,
  },
  {
    path: "/shopper",
    name: "Shopper",
    component: ShopperProfile,
    beforeEnter: _shopperInfoGuard,
  },
  {
    path: "/register-building",
    name: "CreateBuilding",
    component: CreateBuilding,
    beforeEnter: _authGuard,
  },
  {
    path: "/edit-building",
    name: "edit-building",
    component: editBuilding,
    beforeEnter: _authGuard,
  },
  {
    path: "/edit-guard",
    name: "editSecurityGuard",
    component:  editSecurityGuard,
    beforeEnter: _authGuard,
  },
  {
    path: "/register-security-guard",
    name: "register-security-guard",
    component: registerSecurityGuard,
    beforeEnter: _authGuard,
  }
];

export default routes;

<template>
  <figure>
    <img src="../assets/svg/tipti_logo.svg" alt="tipti logo" />
    <figcaption>
      <p>TIENDA Y</p>
      <p>SUPERMECADO</p>
      <p>A DOMICILIO</p>
    </figcaption>
  </figure>
</template>

<style scoped>
figure {
  margin: 0;
  display: flex;
  padding: 2rem 0;
  align-items: center;
  justify-content: center;
}
img {
  width: 30%;
}
figcaption {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-left: 1rem;
}
p {
  margin: 0;
}

@media (min-width: 600px) {
  img {
    width: 150px;
  }
}
</style>
export default {
  shopper(state, shopper) {
    return state.shopper;
  },
    listShopper(state) {
    return state.listShopper;
    },
  validationCodeShopper(state) {
    return state.validationCodeShopper;
  }


};

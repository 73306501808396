<template>
  <div class="auth-container">
    <main-header />
    <div class="body">
      <div class="body__left">
        <h1 class="body__left__title">
          <span class="body__left__title__top">
            Módulo
          </span>
          <span class="body__left__title__bottom">
            DE SEGURIDAD
          </span>
        </h1>
        <icon-key :color="'#ff9012'" :size="iconSize" />
          <p class="text-container text-slogan">
          Esta es una plataforma de Tipti S.A., para resguardar la seguridad de
          sus clientes. En esta plataforma puedes verificar los perfiles de
          nuestros shopper cuando realicen una entrega en tu edificio, conjunto
          o condominio.
        </p>
      </div>

      <div class="body__right">
        <the-register v-if="isRegister" @switchForm="switchForm" />
        <the-login v-if="!isRegister" @switchForm="switchForm" />
      </div>
    </div>
    <main-footer />
  </div>
</template>

<script>
import TheRegister from "@/components/TheRegister.vue";
import { checkMobileBrowser } from "@/utils/utils.js";
import TheLogin from "@/components/TheLogin.vue";
import IconKey from "@/components/icons/IconKey.vue";
import MainHeader from "@/components/UI/headers/MainHeader";
import MainFooter from "@/views/auth/MainFooter";

export default {
  name: "Authetification",
  data() {
    return {
      isRegister: false
    };
  },
  components: {
    MainFooter,
    MainHeader,
    TheRegister,
    TheLogin,
    IconKey
  },
  computed: {
    checkMobileBrowser() {
      return checkMobileBrowser();
    },
    iconSize() {
      return window.innerWidth < 800 ? 0.5 : 0.9;
    }
  },
  methods: {
    switchForm() {
      this.isRegister = !this.isRegister;
    }
  }
};
</script>

<style scoped>
.auth-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-image: url("../..//assets/img/background_circle.png");
  background-size: 30rem;
  background-position: bottom;
  background-position-x: calc(100% + 15rem);
  background-position-y: calc(100% + 5rem);
}

.body {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  justify-items: center;
  position: relative;
  margin: 2rem 0;
}
.body__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.body__left__title span {
  text-align: center;
  display: block;
  color: #ff9012;
  box-sizing: border-box;
}
.body__left__title__top {
  font-size: 128px;
}
.body__left__title__bottom {
  font-size: 64px;
  font-weight: 300;
}
.body__right {
  display: flex;
  position: relative;
  align-items: center;
}

.footer img {
  height: 100%;
}

@media (max-width: 800px) {
  .auth-container {
    background: unset;
  }
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .body__right {
    left: unset;
    justify-self: center;
    align-self: center;
  }
  .body__left__title__top {
    line-height: 3rem;
    font-size: 55px;
  }
  .body__left__title__bottom {
    font-size: 33px;
  }
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-slogan {
  width: 50%;
  font-weight: 200;
  text-align: center;
  margin: 1rem 0;
  color: #606060;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 0em;
}
</style>

<template>
  <button class="btn-return" @click="onPressed">
    <img
      src="@/assets/svg/ic_btn_return.svg"
      alt="button retornar"
    />
    <p class="return-text">REGRESAR</p>
  </button>
</template>

<script>
export default {
  props: {
    onPressed: {
      type: Function,
    },
  },
};
</script>

<style scoped>
img {
  height: 30px;
}
.btn-return {
  all: unset;
  display: flex;
  align-items: center;
}
.btn-return :hover{
  cursor: pointer;
}
.return-text {
  padding-left: 20px;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  color: #ff9012;
}
</style>

<template>
  <section :class="{ menu__header_photo: isFromMenu }">
    <icon-profile
      v-if="showProfileIcon || isFromMenu"
      :size="isLarge ? 4 : 1"
    />
    <img
      v-else-if="photo?.length"
      :src="photo"
      class="guard-photo"
      :class="{ 'lg-size': isLarge, border: hasBorder }"
      :style="{ 'border-color': borderColor }"
      alt=""
    />
    <icon-user v-else :color="color" :size="isLarge ? 4 : 1.5"></icon-user>
    <p v-if="isFromMenu">
      {{ securityGuardName?.security_guard_name?.toUpperCase() }}
    </p>
  </section>
</template>

<script>
import IconUser from "@/components/icons/IconUser.vue";

import IconProfile from "@/components/icons/IconProfile";
export default {
  name: "UserPhoto",
  components: {
    IconProfile,
    IconUser
  },
  props: {
    photo: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#FF9012"
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    borderColor: {
      type: String,
      default: "#FFF"
    },
    isFromMenu: {
      type: Boolean,
      default: false
    },
    showProfileIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    securityGuardName() {
      return this.$store.state.user?.user;
    }
  }
};
</script>

<style scoped>
.guard-photo {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.border {
  border: 3px solid;
}
.lg-size {
  height: 15vh;
  width: 15vh;
}
.menu__header_photo {
  display: flex;
  align-items: center;
}
.menu__header_photo > p {
  margin: 0 1rem;
}
</style>

import { createStore } from "vuex";
import userModule from "./modules/user/index";
import shopperModule from "./modules/shopper/index";
import NetworkModule from "./modules/network/index";
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
import building from "@/store/modules/building";

const ls = new SecureLS({ isCompression: false });
const modules = {
  user: userModule,
  shopper: shopperModule,
  network: NetworkModule,
  building: building
};

export default createStore({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  modules: modules,
  strict: false,
});

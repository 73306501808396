<template>
  <base-card>
    <form class="edit-profile" @submit.prevent="editProfile">
      <h2 class="header_name">
        {{ securityGuard?.security_guard_name?.toUpperCase() }}
      </h2>
      <user-photo
          show-profile-icon
          :photo="securityGuard?.security_guard_picture ?? require('@/assets/svg/profileimg.svg')"
          is-large
      />
      <div class="edit-profile__inputs">
        <base-input
            label="Nombre y Apellido:"
            :placeholder="securityGuard?.security_guard_name"
            :isDisabled="true"
            autocomplete="username"
            :v-model="name"
        />
        <base-input
            label="Correo electrónico:"
            :placeholder="securityGuard?.security_guard_username"
            autocomplete="email"
            :isDisabled="true"
            type="email"
        />
        <base-input
            label="Contraseña"
            placeholder="Ingresa tu nueva contraseña"
            type="password"
            autocomplete="current-password"
            v-model="password"
        />
      </div>
      <loading v-if="isLoading"></loading>
      <base-button v-else txt="Guardar"></base-button>
    </form>
  </base-card>
  <teleport to="body">
    <alert
        @click="showSuccessAlert = false"
        backgroundColor="transparent"
        body="Se ha cambiado la contraseña con éxito"
        v-if="showSuccessAlert"
        isSuccess
    ></alert>
  </teleport>
</template>

<script>
import BaseCard from "@/components/UI/card/BaseCard.vue";
import UserPhoto from "@/components/UserPhoto.vue";
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import Loading from "@/components/UI/loading/Loading.vue";
import BaseInput from "@/components/UI/inputs/BaseInput";
import Alert from "@/components/UI/alerts/Alert";

export default {
  name: "EditProfile",
  data() {
    return {
      isLoading: false,
      password: "",
      showSuccessAlert: false
    };
  },
  components: {
    BaseCard,
    UserPhoto,
    BaseButton,
    BaseInput,
    Loading,
    Alert,
  },
  props: {
    onPressed: {type: Function},
  },
  methods: {
    async editProfile() {
      try {
        if (!this.password?.length || this.isLoading) return;
        this.isLoading = true;
        await this.$store.dispatch("user/editProfile", this.password);
        this.showSuccessAlert = true;
      }
      finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    securityGuard() {
      return this.$store.state.user.user;
    },
  },
};
</script>

<style scoped>
.edit-profile {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.edit-profile__inputs {
  width: 100%;
  margin: 2rem auto;
}

.header_name {
  font-size: 24px;
  font-weight: 600;
  margin: 1rem 0;
  color: #606060;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import TheTipti from './components/TheTipti.vue'
import router from './router'
import store from './store'


const app = createApp(App).use(store).use(router);
app.component('the-tipti', TheTipti)
    .mount('#app')

<template>
  <div class="button-list">
    <div class="dropdown ">
      <base-button
        :pathSvgRight="require('@/assets/svg/dropDawn.svg')"
        :is-orange="false"
        :active="dropDawnBuilding"
        @click="dropDawnBuilding"
        class="button-create-building drop-btn"
        txt=""
        :txtButton="txt"
      >
      </base-button>
      <div id="my-dropdown" class="dropdown-content">
        <div v-for="(building, index) in buildings" :key="index">
          <base-button
            :pathSvg="require('@/assets/svg/building.svg')"
            :is-orange="false"
            @click="idBuilding(building, index)"
            class="button-create-building--open"
            :txt="building.name"
          />
        </div>
        <base-button
          :pathSvg="require('@/assets/svg/add-saving.svg')"
          :is-orange="false"
          @click="createBuilding"
          class="button-create-building--open"
          txt="&#160;Crear U. Habitacional"
        />
      </div>
    </div>
    <base-button
      v-if="showDetailBuilding && buildings.length > 0"
      is-out-line
      :pathSvg="require('@/assets/svg/add.svg')"
      :is-orange="false"
      @click="createSecurityGuard"
      class="button-create-building drop-btn"
      txt="&#160;Crear Guardia de seguridad "
    >
    </base-button>
  </div>
  <detail-building
    v-if="showDetailBuilding"
    :key="indexBuilding"
    :building="indexBuilding"
  ></detail-building>
</template>
<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import DetailBuilding from "@/views/HomeAdmin/components/DetailBuilding.vue";

export default {
  name: "ListBuilding",
  components: { DetailBuilding, BaseButton },
  data() {
    return {
      accessPoint: [],
      indexBuilding: "",
      buildingIndex: parseInt(localStorage.getItem("idBuilding")),
      txt: "Unidades Habitacionales",
      showDetailBuilding: false
    };
  },
  async mounted() {
    await this.$store.dispatch("building/getBuilding");
    await this.changeBuildingHistory();
  },

  computed: {
    buildings() {
      return this.$store.state.building.building ?? [];
    }
  },
  methods: {
    async changeBuildingHistory() {
      if (this.buildingIndex == undefined) {
        this.buildingIndex = parseInt(localStorage.getItem("numberBuilding"));
      }
      this.buildingIndex = parseInt(localStorage.getItem("idBuilding"));
      await this.idBuilding(
        this.$store.state.building.building[this.buildingIndex]
      );
    },
    idBuilding(building, index) {
      if (building == undefined) return;
      this.$store.commit("building/indexBuildingState", index);
      this.indexBuilding = building;
      this.showDetailBuilding = true;
      if (index == undefined) return;
      localStorage.setItem("idBuilding", index);
    },
    createBuilding() {
      this.$router.push({ name: "CreateBuilding" });
    },

    dropDawnBuilding() {
      document.getElementById("my-dropdown").classList.toggle("show");
      window.onclick = function(event) {
        if (!event.target.matches(".drop-btn")) {
          let dropdowns = document.getElementsByClassName("dropdown-content");
          let i;
          for (i = 0; i < dropdowns.length; i++) {
            let openDropdown = dropdowns[i];
            if (openDropdown.classList.contains("show")) {
              openDropdown.classList.remove("show");
            }
          }
        }
      };
    },
    createSecurityGuard() {
      this.buildingIndex = localStorage.getItem("idBuilding");
      this.$router.push({
        name: "register-security-guard",
        params: { id: this.indexBuilding.id }
      });
    }
  }
};
</script>
<style scoped>
.button-create-building {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  padding: 0rem 1rem;
  margin: 1rem;
  font-size: 1rem;
}

.button-create-building--open {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  margin: 0.25rem 1rem;
  color: #606060;
  padding: 0rem 3rem;
  font-size: 1.25rem;
  font-weight: 400;
  cursor: pointer;
  border: none;
  justify-content: space-between;
  white-space: nowrap;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.drop-btn {
  font-size: 1rem;
  color: #ff9012;
  padding: 0rem 3rem;
  font-size: 1.25rem;
  font-weight: 400;
  cursor: pointer;
  border: none;
  justify-content: space-between;
  white-space: nowrap;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.drop-btn:hover,
.drop-btn:focus {
  border: 1px solid #ff9012;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;

  min-width: 160px;
  overflow: auto;
  z-index: 1;
}
.dropdown a:hover {
  background-color: #ddd;
}
.show {
  display: block;
}
.button-list {
  display: flex;
  flex-direction: row;
}
</style>

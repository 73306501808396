<template>
  <the-header>
    <template v-slot:right>
      <router-link class="menu__photo" v-if="isAuth" :to="linkDestination">
        <user-photo :photo="securityGuardPhoto" :is-from-menu="true" />
      </router-link>
    </template>
  </the-header>
</template>

<script>
import UserPhoto from "@/components/UserPhoto.vue";
import TheHeader from "./TheHeader.vue";
import { mapGetters } from "vuex";

export default {
  name: "MainHeader",
  components: {
    UserPhoto,
    TheHeader
  },
  data() {
    return {
      linkDestination: { name: "GuardProfile" }
    };
  },

  computed: {
    ...mapGetters({
      isAuth: "user/isAuth"
    }),
    securityGuard() {
      return this.$store.state.user?.user;
    },
    securityGuardPhoto() {
      return this.$store.state.user?.user?.security_guard_picture;
    }
  }
};
</script>

<style scoped>
.menu__photo {
  text-decoration: unset;
  color: #ff9012;
  font-size: 14px;
  justify-self: flex-end;
  margin-right: 2rem;
}
</style>

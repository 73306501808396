<template>
  <main-header />
  <the-return-button
    class="back-button"
    :onPressed="returnHome"
  ></the-return-button>

  <div class="card" v-for="shoppers in list" :key="shoppers">
    <div class="card-list">
      <h3 class="title">INFORMACIÓN DE SHOPPER</h3>

      <div class="card-shopper">
        <user-photo

          :photo="shoppers.shopper_picture"
          :isLarge="true"
          :hasBorder="true"
          :borderColor="colorState"
        ></user-photo>
        <div class="image-profile-shoppers">
          <img src="@/assets/svg/scude.svg" alt="" class="img" />
        </div>

        <div>
          <p class="label-name">
            <b> {{ shoppers.shopper_name }}</b>
          </p>
          <p class="shopper-name-txt"></p>
          <p>
            <b class="subtitle">CI:</b> {{ shoppers.shopper_document }}&emsp;
          </p>
          <p><b class="subtitle">Telf:</b> {{ shoppers.shopper_phone }}</p>
          <p class="shopper-uniform-code">
            <b class="subtitle">Codigo de uniforme:</b>
            {{ shoppers.shopper_clothes_code }}
          </p>
        </div>
      </div>
    </div>
    <div class="card-list">
      <div class="scroll-order">
        <h3 class="title">PEDIDOS POR ENTREGAR</h3>
        <template v-for="order in shoppers.orders" :key="order">
          <the-order-details :order="order" />
        </template>
      </div>
    </div>
    <div class="card-list ">
      <div class="card-vehicle">
        <h3 class="title">DATOS DE VEHÍCULOS</h3>
        <div class="car-content">
          <div v-for="(car, i) in shoppers.vehicles" :key="car">
            <div class="car-date__text">
              <div class="circle-car"></div>
              <h4 class="car-subtitle">Datos del vehículo {{ i + 1 }}:</h4>
            </div>
            <p>{{ car.vehicle_type }}</p>
            <p>Placa: {{ car.vehicle_plate }}</p>
            <div class="car-color">
              <p>Color:</p>
              <div
                v-if="car.vehicle_color"
                class="car-color__palette"
                :style="{ 'background-color': car.vehicle_color }"
              ></div>
              <p v-else>No asignado</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-list">
      <h3 class="title">ACCIONES</h3>
      <div class="button-list">
        <div class="btn-container-report">
          <the-report-case
            :shopper-available="shoppers.shopper_available"
            :shopper-id="shoppers.shopper_id"
          />
        </div>
        <button
          class="btn-container"
          @click="checkOut(shoppers.shopper_entry_id)"
        >
          REGISTRAR SALIDA
        </button>
      </div>
    </div>
  </div>
  <main-footer />
  <teleport to="body">
    <alert
      @click="showReportExitDialogSuccess = false"
      body="Se registro salida del shopper exitosamente."
      v-if="showReportExitDialogSuccess"
      :isSuccess="true"
    />
  </teleport>
</template>

<script>
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import MainFooter from "@/views/auth/MainFooter.vue";
import TheReturnButton from "@/components/UI/buttons/TheReturnButton.vue";
import UserPhoto from "@/components/UserPhoto.vue";

import TheOrderDetails from "@/views/shopperProfile/components/TheOrderDetails.vue";
import TheReportCase from "@/views/shopperProfile/components/TheReportCase.vue";
import Alert from "@/components/UI/alerts/Alert.vue";

export default {
  name: "list-profiles",
  components: {
    Alert,
    TheReportCase,
    TheOrderDetails,
    UserPhoto,
    TheReturnButton,
    MainFooter,
    MainHeader
  },
  mounted() {
    this.getListShopper();
  },
  data() {
    return {
      listShopperActives: [],
      showReportExitDialogSuccess: false
    };
  },
  computed: {
    list() {
      return this.$store.state.shopper.listShopper;
    },

    colorState() {
      return "#CFDE00";
    }
  },
  methods: {
    returnHome() {
      this.$router.push({ name: "Home" });
    },

    getListShopper() {
      this.$store.dispatch("shopper/getListShopper", {
        residential_complex_id: this.$store.state.user?.user
          ?.security_guard_current_residential_complex
      });
    },
    checkOut(shopper_entry_id) {
      this.$store.dispatch("shopper/checkOut", {
        shopper_entry_id: shopper_entry_id
      });
      this.showReportExitDialogSuccess = true;
      setTimeout(() => {
        this.showReportExitDialogSuccess = false;
        this.getListShopper();
      }, 3000);
      this.getListShopper();
    }
  }
};
</script>

<style scoped>
.back-button {
  margin-left: 2rem;
}
.card {
  display: flex;
  border-radius: 4px;
  padding: 35px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.title {
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  color: #606060;
}
.image-profile-shoppers {
  margin: 0 0 -4rem -2rem;
}
.img {
  width: 80%;
  height: 80%;
}
.card-shopper {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
}
.car-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}
@media (max-width: 300px) {
  .car-content {
    grid-template-columns: 1fr;
  }
}

.car-subtitle {
  font-weight: 600;
  color: #606060;
  white-space: nowrap;
  margin: 0 12px;
}
.car-color__palette {
  border-radius: 50%;
  border: 2px solid #000;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.car-color {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}
.circle-car {
  height: 15px;
  width: 15px;
  margin: 0 3px 0 0;
  border-radius: 50%;
  background-color: #ff9012;
}
.car-date__text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.button-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2rem;
}
.btn-container {
  all: unset;
  height: 2.5em;
  width: 11em;
  margin: 0 2rem;
  padding: 0 4em;
  cursor: pointer;
  font-size: 14px;
  line-height: 0.1;
  white-space: nowrap;
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  background-color: #ff9012;
  color: #fff;
}
.btn-container-report {
  all: unset;
  height: 2.5em;
  width: 11em;
  margin: 0 auto 1rem;
  padding: 0 4em;
  cursor: pointer;
  font-size: 14px;
  line-height: 0.1;
  white-space: nowrap;
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  background-color: #c90808;
  color: #fff;
}

.card-list {
  margin: 0.5rem;
  padding: 1rem;
  display: flex;
  height: 17rem;
  width: 27rem;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px #606060;
  @media only screen and (max-width: 1200px) {
  height: 20rem;
    width: 21rem;
  }
  @media only screen and (max-width: 600px) {
    height: 20rem;
    width: 20rem;
  }
}
.scroll-order {
  height: 13rem;
  line-height: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
  gap: 1rem;
  max-height: 12rem;
}
</style>

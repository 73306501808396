import { logger } from "../../../utils/logger";
import { downloadFile } from "../../../utils/utils";

export default {
  async login({ rootGetters, commit }, payload) {
    try {
      const url = "api-token-security-guard/";
      const { data } = await rootGetters["network/axios"].post(url, payload);
      commit("token", data["token"]);
      commit("isAdmin", data["is_admin"]);
    } catch (err) {
      throw Error(err);
    }
  },
  async register({ rootGetters }, payload) {
    try {
      const url = `v2/residential_complex_security/security_guard`;
      const formData = new FormData();
      formData.append("first_name", payload.first_name);
      formData.append("last_name", payload.last_name);
      formData.append("security_company", payload.security_company);
      formData.append("email", payload.email);
      formData.append("document", payload.document);
      formData.append("phone", payload.phone);
      formData.append("password", payload.password);
      formData.append("residential_complex", payload.id);
      formData.append("enable", payload.enabled_guard);
      formData.append("profile_picture", payload.profile_picture);

      await rootGetters["network/axios"].post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } catch (err) {
      throw err.response.status;
    }
  },

  async information({ rootGetters, commit }) {
    try {
      const url = "v2/residential_complex_security/security_guard";
      const { data } = await rootGetters["network/axios"].get(url);
      commit("user", data["data"]);
    } catch (err) {
      logger("INFORMATION", err);
    }
  },
  async registerAdmin({ rootGetters }, payload) {
    try {
      const url = "v2/residential_complex_security/administrator/";
      const data = {
        first_name: payload["first_name"],
        last_name: payload["last_name"],
        email: payload["email"],
        document: payload["document"],
        password: payload["password"]
      };
      await rootGetters["network/axios"].post(url, data);
    } catch (err) {
      throw err.response.status;
    }
  },

  async editProfile({ rootGetters }, password) {
    try {
      const url =
        "v2/residential_complex_security/security_guard/reset_password";
      const dataModel = {
        password: password
      };
      await rootGetters["network/axios"].put(url, dataModel);
    } catch (err) {
      logger("EDIT_PROFILE", err);
    }
  },
  async getProfilesGuards({ rootGetters, commit }, payload) {
    try {
      const url = `v2/residential_complex_security/security_guard/all/${payload["id"]}`;
      const { data } = await rootGetters["network/axios"].get(url);
      if (!data) {
        commit("profiles", "");
        return;
      }
      commit("profiles", data);
    } catch (err) {
      logger("INFORMATION", err);
    }
  },
  async getProfilesGuardsId({ rootGetters, commit }, payload) {
    try {
      const url = `v2/residential_complex_security/security_guard/${payload["id"]}`;
      const { data } = await rootGetters["network/axios"].get(url);
      commit("profileId", data);
      return data;
    } catch (err) {
      logger("INFORMATION", err);
    }
  },
  async editProfileGuard({ rootGetters }, payload) {
    try {
      const url = `v2/residential_complex_security/security_guard/${payload["id"]}`;

      const formData = new FormData();
      formData.append("first_name", payload.first_name);
      formData.append("last_name", payload.last_name);
      formData.append("security_company", payload.security_company);
      formData.append("email", payload.email);
      formData.append("document", payload.document);
      formData.append("phone", payload.phone);
      formData.append("residential_complex", payload.id);
      formData.append("enable", payload.enabled_guard);
      formData.append("profile_picture", payload.profile_picture);
      if (payload.password) {
        formData.append("password", payload.password);
      }

      await rootGetters["network/axios"].put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    } catch (err) {
      logger("EDIT_PROFILE", err);
    }
  },
  async deleteBuildingGuard({ rootGetters, commit }, payload) {
    try {
      const url = `v2/residential_complex_security/security_guard/${payload["id"]}`;
      await rootGetters["network/axios"].delete(url);
      commit("deleteProfile", payload["id"]);
    } catch (err) {
      throw err.response.status;
    }
  },
  async downloadReportShopperEntries({ rootGetters }, payload) {
    const url = "/v2/residential_complex_shopper_entry_report";
    const options = { responseType: "blob" };
    const response = await rootGetters["network/axios"].get(url, {
      responseType: "blob",
      params: payload
    });

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([response.data], { type: contentType });
    const filename = "Reporte de entradas shopper.xlsx";
    await downloadFile(blob, filename);
  },

  updateCareTeamChat() {
    try {
      let chat = process.env.VUE_APP_CHATBOT_EC;
      const head = document.getElementsByTagName("head")[0];
      const chatSetted = document.getElementById("bim-snippet");
      const chatFrame = document.getElementById("bim-ifr");
      if (chatFrame) chatFrame?.parentNode?.removeChild(chatFrame);
      if (chatSetted) head.removeChild(chatSetted);
      /// ** set new chat script;
      const script = document.createElement("script");
      script.src = chat;
      script.id = "bim-snippet";
      script.type = "text/javascript";
      script.setAttribute("position", "right");
      head.appendChild(script);
    } catch (err) {
      logger("UPDATE_CARE_TEAM_CHAT", err);
    }
  }
};

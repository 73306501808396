<template>
  <button class="text-button-solid-container" :class="{'text-button-solid-container--disable' :isDisable}">
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "text-button-solid",
  props: {
    text: {
      type: String,
      required: true,
    },
      isDisable: {
        type: Boolean,
          default: false
      }
  },
};
</script>

<style scoped>
.text-button-solid-container {
  all: unset;
  height: 40px;
  max-width: 250px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  font-size: 14px;
  line-height: 0.1;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  background-color: #ff9012;
  color: #ffffff;
}
.text-button-solid-container--disable {
    background-color: #BDBEC3;
    color: #ffffff;
    cursor: not-allowed;
}
</style>

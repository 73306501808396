<template>
  <base-icon
    :icon-name="label"
    :width="width"
    :height="height"
    viewBox="0 0 39 33"
    :iconColor="color"
  >
    <path
      d="M12.463 17.5163C14.1396 17.5163 15.4853 16.2148 15.5514 14.538L15.9044 3.63996H17.559C17.8017 3.63996 18.0002 3.44145 18.0002 3.19878C18.0002 2.95612 17.8017 2.7576 17.559 2.7576H12.9262V1.76491C12.9262 0.79419 12.132 0 11.1613 0H7.80819C6.83747 0 6.04328 0.79419 6.04328 1.76491V2.7576H1.38844C1.14577 2.7576 0.947266 2.95612 0.947266 3.19878C0.947266 3.44145 1.14578 3.63996 1.38844 3.63996H3.04301L3.39601 14.538C3.46219 16.2146 4.80791 17.5163 6.48445 17.5163H12.463ZM6.92572 1.76487C6.92572 1.27953 7.32273 0.882512 7.80807 0.882512H11.1614C11.6467 0.882512 12.0437 1.27953 12.0437 1.76487V2.75757H6.9256L6.92572 1.76487ZM4.30044 14.494L3.94743 3.61798H15.044L14.6692 14.494C14.625 15.6852 13.6543 16.6339 12.4631 16.6339H6.50666C5.3153 16.6339 4.3446 15.7072 4.30044 14.494Z"
      fill="#FF9012"
    />
    <path
      d="M9.48219 13.8096C9.72487 13.8096 9.92337 13.6111 9.92337 13.3684V6.77223C9.92337 6.52956 9.72486 6.33105 9.48219 6.33105C9.23953 6.33105 9.04102 6.52957 9.04102 6.77223V13.3684C9.04102 13.6109 9.23953 13.8094 9.48219 13.8094V13.8096Z"
      fill="#FF9012"
    />
    <path
      d="M6.8279 13.8096C7.07057 13.8096 7.26907 13.6111 7.26907 13.3684V6.77223C7.26907 6.52956 7.07056 6.33105 6.8279 6.33105C6.58522 6.33105 6.38672 6.52957 6.38672 6.77223V13.3684C6.38672 13.6109 6.58523 13.8094 6.8279 13.8094V13.8096Z"
      fill="#FF9012"
    />
    <path
      d="M12.1365 13.8096C12.3792 13.8096 12.5777 13.6111 12.5777 13.3684V6.77223C12.5777 6.52956 12.3792 6.33105 12.1365 6.33105C11.8938 6.33105 11.6953 6.52957 11.6953 6.77223V13.3684C11.6953 13.6109 11.8938 13.8094 12.1365 13.8094V13.8096Z"
      fill="#FF9012"
    />
  </base-icon>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";
export default {
  name: "icon-delete",
  props: {
    size: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: "#ff9012"
    },
    iconName: {
      type: String
    }
  },
  computed: {
    width() {
      return 39 * this.size;
    },
    height() {
      return 30 * this.size;
    },
    label() {
      return this.iconName ?? "";
    }
  },
  components: {
    BaseIcon
  }
};
</script>

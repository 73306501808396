<template>
  <main-header></main-header>
  <the-return-button class="back-button" :onPressed="returnHomeAdmin" />
  <div class="form-center">
    <form class="form-register" @submit.prevent="registerUser" ref="anyName">
      <h2>Registrar Guardia</h2>
      <div class="inputs-container">
        <img
          :src="previewImage || require('@/assets/svg/profileimg.svg')"
          class="img-round"
        />
        <label for="myFile" class="file-image">Subir fotografía</label>
        <input
          @change="handleFileChange"
          type="file"
          :v-model="profilePicture"
          class="file-input"
          id="myFile"
          name="myFile"
          accept="image/png, image/jpeg"
        />

        <base-text-input
          v-model.trim="username"
          label="Nombre y Apellido:"
          placeholder="Ingresa tus nombres completos"
        />
        <base-text-input
          v-model.trim="identityDocument"
          label="Documento de identidad:"
          placeholder="Ingresa documento de identidad"
          :type="type"
        />
        <base-text-input
          v-model.trim="telephone"
          label="Celular:"
          placeholder="Ingresa número de celular"
          :type="type"
          :max-length="maxLength"
        />
        <base-text-input
          v-model.trim="email"
          label="Correo electrónico:"
          placeholder="Ingresa tu Email"
          type="email"
        />
        <base-text-input
          v-model.trim="password"
          class="password-input"
          type="password"
          label="Contraseña:"
          placeholder="Ingresa tu contraseña"
        />

        <base-text-input
          v-model.trim="securityCompany"
          label="Compañia de seguridad:"
          placeholder="Ingresa el nombre de la compañiad de seguridad"
        />
        <div class="check-text">
          <label>Habilitar Guardia&nbsp; </label>
          <input
            placeholder="Habilitar Guardia"
            class="check"
            v-model="enabledGuard"
            type="checkbox"
            checked
          />
        </div>
        <p class="error" v-if="errorMessage.length">{{ errorMessage }}</p>
      </div>
      <footer class="register__footer">
        <loading v-if="isLoading"></loading>
        <text-button-solid v-else :is-disable="isDisable" text="Guardar" />
      </footer>
    </form>
  </div>
  <teleport to="body">
    <alert
      title="Registro exitoso!"
      @click="closeAlert"
      body="Tu usuario se registró existosamente. Pronto un agente de Tipti se comunicará contigo para verificar los datos ingresados."
      v-if="showSuccessAlert"
      :isSuccess="true"
    />
  </teleport>
  <main-footer />
</template>

<script>
import Loading from "@/components/UI/loading/Loading.vue";
import Alert from "@/components/UI/alerts/Alert.vue";
import BaseTextInput from "@/components/UI/inputs/BaseInput";
import TextButtonSolid from "@/components/UI/buttons/TextButtonSolid";
import MainHeader from "@/components/UI/headers/MainHeader.vue";
import MainFooter from "@/views/auth/MainFooter.vue";
import TheReturnButton from "@/components/UI/buttons/TheReturnButton.vue";

export default {
  name: "register-security-guard",
  data() {
    return {
      username: "",
      email: "",
      password: "",
      identityDocument: "",
      errorMessage: "",
      securityCompany: "",
      telephone: "",
      profilePicture: "",
      type: "Number",
      enabledGuard: false,
      isLoading: false,
      showSuccessAlert: false,
      showErrorAlert: false,
      showWrongUsername: false,
      showAlreadyUserAlert: false,
      maxLength: 10,
      previewImage: null
    };
  },
  emits: ["switchForm"],
  components: {
    TheReturnButton,
    MainFooter,
    MainHeader,
    TextButtonSolid,
    BaseTextInput,
    Loading,
    Alert
  },
  watch: {
    username() {
      this.errorMessage = "";
    },
    email() {
      this.errorMessage = "";
    },
    password() {
      this.errorMessage = "";
    },
    identityDocument() {
      this.errorMessage = "";
    }
  },
  computed: {
    isDisable() {
      return (
        !this.username.length ||
        !this.email.length ||
        !this.password.length ||
        !this.identityDocument.length ||
        !this.telephone.length
      );
    }
  },
  methods: {
    returnHomeAdmin() {
      this.$router.push({ name: "HomeAdmin" });
    },
    closeAlert() {
      if (this.showSuccessAlert) {
        this.$emit("switch-form");
      }
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
      this.showWrongUsername = false;
      this.showAlreadyUserAlert = false;
    },
    handleFileChange(event) {
      this.profilePictureEdit = event.target.files[0];
      const file = event.target.files[0];
      this.profilePictureEdit = file;
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async registerUser() {
      try {
        if (this.isLoading || this.isDisable) return;
        this.isLoading = true;
        const fullName = this.username.split(" ");
        if (fullName?.length > 1) {
          const firstName = fullName[0];
          const lastName = fullName[1];
          await this.$store.dispatch("user/register", {
            first_name: firstName,
            last_name: lastName,
            document: this.identityDocument,
            phone: this.telephone,
            email: this.email.toLowerCase(),
            profile_picture: this.profilePictureEdit,
            security_company: this.securityCompany,
            password: this.password,
            id: this.$route.params.id,
            enabled_guard: this.enabledGuard
          });
          this.showSuccessAlert = true;
          this.submitForm();
        } else {
          this.errorMessage =
            "Ingresa por lo menos un nombre y un apellido. Ej: German Caicedo";
        }
      } catch (statusCode) {
        if (statusCode === 409)
          this.errorMessage =
            "Este correo ya fue registrado anteriormente intenta nuevamente con otro correo.";
        else
          this.errorMessage =
            "Verifica que todos los campos esten llenos correctamente y vuelve a intentarlo.";
      } finally {
        this.isLoading = false;
      }
    },
    submitForm() {
      this.$refs.anyName.reset();
      setTimeout(() => {
        this.closeAlertresister();
      }, 2000);
    },
    closeAlertresister() {
      this.showSuccessAlert = false;

      this.$router.push({ name: "HomeAdmin" });
    }
  }
};
</script>

<style scoped>
.form-center {
  align-self: center;
}
.form-register {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 25rem;
  padding: 0 0 2rem 0;
}

.inputs-container {
  display: flex;
  margin: 1rem 2rem 3rem;
  flex-direction: column;
}
h2 {
  text-align: center;
  font-size: 2rem;
  margin: 0;
  color: #ff9012;
  font-weight: 600;
}
p {
  color: #606060;
  font-size: 12px;
  align-self: center;
}
.register__footer {
  display: flex;
  flex-direction: column;
}
.register__footer_text {
  color: #ff9012;
  align-self: center;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
}
.error {
  margin: 0;
  color: #c90808;
  font-size: 12px;
  text-align: justify;
}
@media (max-width: 600px) {
  .form-register {
    min-width: unset;
    justify-items: center;
    align-items: center;
    justify-content: center;
  }
  h2 {
    font-size: 30px;
    width: 100%;
  }

  .inputs-container {
    margin: 0;
    width: 100%;
  }
}
.img-round {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  align-self: center;
}
.file-input {
  display: none;
}
.file-image {
  align-self: center;
  text-decoration-line: underline;
}
.check {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  accent-color: #ff9012;
}
.check-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.back-button {
  margin-left: 2rem;
}
</style>

<template>
  <div class="date-range-dialog">
    <div class="dialog-overlay" @click="$emit('close-dialog')"></div>
    <div class="dialog-content">
      <div class="header">
        <h2>{{ title }}</h2>
        <img
          class="img-close"
          @click="$emit('close-dialog')"
          src="@/assets/svg/ic_close.svg"
          alt="cerrar modal"
        />
      </div>
      <label class="instruction-label"
        >Ingresa la fecha para descargar el reporte</label
      >
      <div class="date-row">
        <div class="date-col">
          <label class="date-label" for="from-date">Fecha desde:</label>
          <input
            id="from-date"
            class="input-qr__input"
            type="date"
            v-model="fromDate"
            :max="currentDate"
          />
        </div>
        <div class="date-col">
          <label class="date-label" for="to-date">Fecha hasta:</label>
          <input
            id="to-date"
            class="input-qr__input"
            type="date"
            v-model="toDate"
            :max="currentDate"
          />
        </div>
      </div>
      <div class="validation-row">
        <span v-if="showValidationError" class="validation-error">
          Por favor, seleccione ambas fechas.
        </span>
        <span v-if="showRequestError" class="validation-error">
          Uups, algo salio mal, intentalo nuevamente en un momento.
        </span>
      </div>
      <base-button
        class="btn-download-report"
        :is-orange="false"
        @click.prevent="downloadReport"
        txt="Descargar reporte"
      >
        <template #icon>
          <div class="icon-download">
            <icon-download></icon-download>
          </div>
        </template>
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import IconDownload from "./icons/IconDownload.vue";

export default {
  components: {
    IconDownload,
    BaseButton
  },
  props: {
    title: {
      type: String,
      default: "Descargar reporte"
    },
    idBuilding: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fromDate: "",
      toDate: "",
      showValidationError: false,
      showSuccessAlert: false,
      showRequestError: false
    };
  },
  computed: {
    currentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  },
  methods: {
    async downloadReport() {
      this.showRequestError = false;
      if (!this.fromDate || !this.toDate) {
        this.showValidationError = true;
        return;
      }
      this.showValidationError = false;

      let payload = {
        date_from: this.fromDate,
        date_to: this.toDate,
        complex_id: this.idBuilding
      };
      try {
        await this.$store.dispatch(
          "user/downloadReportShopperEntries",
          payload
        );
      } catch (error) {
        this.showRequestError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style
  src="../../src/components/UI/inputs/baseInputQR/base-input-qr.css"
  scoped
/>

<style scoped>
.date-range-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.dialog-content {
  width: 500px;
  height: 320px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 2.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

h2 {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 550;
  color: #606060;
  flex-grow: 1;
}

.img-close {
  align-self: center;
  height: 30px;
}

.instruction-label {
  text-align: center;
  font-size: 15px;
  font-weight: 501;
  color: #606060;
}

.date-row {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin: 0 0 1rem 0;
  width: 100%;
}

.date-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
}

.date-label {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #404040;
}

.btn-download-report {
  color: #ff9012;
  border: 1px solid #dfdfdf;
  font-weight: 501;
  padding: 0.5rem 1rem; /* Disminuye el valor de padding-right */
  white-space: nowrap; /* Esto evitará que el texto se ajuste a varias líneas */
}

.validation-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.validation-error {
  color: red;
  font-size: 14px;
  text-align: center;
}

input[type="date"] {
  width: 100%;
}

.modal-mask {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.img-close {
  align-self: center;
  height: 30px;
}
.img-success {
  align-self: center;
  height: 5rem;
}

.icon-download {
  padding: 1rem;
  background-color: #ff9012;
  margin-left: 2rem;
}
</style>

<template>
  <base-icon
    :icon-name="label"
    :width="width"
    :height="height"
    viewBox="0 0 40 40"
    :iconColor="color"
  >
    <path
      d="M33.8698 5.91377C32.1363 4.17051 30.0742 2.78832 27.803 1.84718C25.5318 0.906047 23.0965 0.42465 20.6381 0.430845V0.430845C17.427 0.430945 14.2699 1.25692 11.4702 2.82939C8.67046 4.40187 6.32224 6.66798 4.65116 9.40998C2.98008 12.152 2.04231 15.2777 1.92798 18.4867C1.81364 21.6958 2.52658 24.8803 3.99828 27.7343L0.851908 36.7233C0.762192 36.9839 0.73579 37.2621 0.774884 37.5348C0.813979 37.8076 0.917447 38.0672 1.07673 38.292C1.23602 38.5169 1.44655 38.7006 1.69091 38.828C1.93527 38.9554 2.20644 39.0228 2.482 39.0246C2.67898 39.0242 2.87448 38.9906 3.06025 38.9251L12.0493 35.7853C16.0175 37.8318 20.5864 38.3926 24.9317 37.3666C29.277 36.3405 33.1125 33.7952 35.7461 30.1898C38.3797 26.5845 39.6381 22.1566 39.294 17.7051C38.9499 13.2536 37.0262 9.0716 33.8698 5.91377V5.91377ZM32.2685 30.7825C29.7762 33.2743 26.5501 34.9004 23.0646 35.4215C19.5791 35.9427 16.0184 35.3314 12.9062 33.6777C12.6943 33.5651 12.4614 33.4974 12.2221 33.479C11.9828 33.4605 11.7423 33.4916 11.5155 33.5704L3.352 36.4316L6.21055 28.2654C6.28913 28.0386 6.32004 27.7981 6.30136 27.5588C6.28267 27.3195 6.21479 27.0866 6.10196 26.8747C4.76887 24.3668 4.10758 21.5564 4.18242 18.7172C4.25726 15.8779 5.06567 13.1063 6.52903 10.672C7.99239 8.23773 10.0609 6.22363 12.5333 4.82563C15.0057 3.42763 17.7978 2.69332 20.6381 2.69414V2.69414C23.8916 2.69568 27.0717 3.6617 29.7764 5.47011C32.4811 7.27851 34.5891 9.84812 35.8338 12.8542C37.0785 15.8603 37.4041 19.1678 36.7695 22.3589C36.1349 25.55 34.5686 28.4814 32.2685 30.7825V30.7825Z"
    />
    <path
      d="M27.6023 20.8194C27.1879 20.406 26.6263 20.1738 26.0409 20.1738C25.4555 20.1738 24.894 20.406 24.4795 20.8194L23.5376 21.7614C21.2091 20.4969 19.2969 18.5847 18.0324 16.2562L18.9743 15.3143C19.3878 14.8998 19.6199 14.3383 19.6199 13.7528C19.6199 13.1674 19.3878 12.6059 18.9743 12.1914L16.4363 9.65341C16.0219 9.23998 15.4603 9.00781 14.8749 9.00781C14.2895 9.00781 13.728 9.23998 13.3135 9.65341L11.2831 11.6838C10.12 12.8469 10.0625 14.8459 11.1195 17.3094C13.5354 22.2568 17.5343 26.2558 22.4818 28.6716C23.4745 29.1312 24.5492 29.3874 25.6425 29.4252C26.0947 29.4464 26.5465 29.3763 26.9709 29.2189C27.3953 29.0616 27.7836 28.8203 28.1125 28.5094L30.143 26.479C30.557 26.0648 30.7897 25.5032 30.7897 24.9176C30.7897 24.3319 30.557 23.7703 30.143 23.3561L27.6023 20.8194ZM26.5086 26.9107C26.1763 27.243 25.1559 27.3594 23.3688 26.5941C18.9559 24.4077 15.3822 20.8339 13.1958 16.4211C12.4304 14.6366 12.5416 13.6161 12.8792 13.2812L14.8703 11.29L17.3299 13.7496L16.1524 14.927C15.8929 15.1853 15.7222 15.5194 15.6648 15.881C15.6074 16.2427 15.6664 16.6132 15.8332 16.9391C17.3676 19.9628 19.8245 22.4196 22.8481 23.954C23.1741 24.1208 23.5446 24.1798 23.9062 24.1224C24.2678 24.0651 24.6019 23.8943 24.8602 23.6348L26.0376 22.4574L28.4972 24.9169L26.5086 26.9107Z"
    />
  </base-icon>
</template>

<script>
import BaseIcon from "./BaseIcon.vue";
export default {
  name: "icon-whatsapp",
  props: {
    size: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: "#ff9012",
    },
    iconName: {
      type: String,
    },
  },
  computed: {
    width() {
      return 40 * this.size;
    },
    height() {
      return 40 * this.size;
    },
    label() {
      return this.iconName ?? "";
    },
  },
  components: {
    BaseIcon,
  },
};
</script>
